export const USER_LOGIN_REQUESTED = "USER_LOGIN_REQUESTED";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";

export const USER_LOGOUT_REQUESTED = "USER_LOGOUT_REQUESTED";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED";

export const USER_REGISTER_REQUESTED = "USER_REGISTER_REQUESTED";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILED = "USER_REGISTER_FAILED";

export const USER_RETRIEVE_SESSION_REQUESTED = "USER_RETRIEVE_SESSION";
export const USER_RETRIEVE_SESSION_SUCCESS = "USER_RETRIEVE_SESSION_SUCCESS";
export const USER_RETRIEVE_SESSION_FAILED = "USER_RETRIEVE_SESSION_FAILED";

export const USER_SET_AVATAR_REQUESTED = "USER_SET_AVATAR_REQUESTED";
export const USER_SET_AVATAR_SUCCESS = "USER_SET_AVATAR_SUCCESS";
export const USER_SET_AVATAR_FAILED = "USER_SET_AVATAR_FAILED";

export const USER_UPDATE_REQUESTED = "USER_UPDATE_REQUESTED";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";

export const USER_RECOVER_PASSWORD_REQUESTED =
  "USER_RECOVER_PASSWORD_REQUESTED";
export const USER_RECOVER_PASSWORD_SUCCESS = "USER_RECOVER_PASSWORD_SUCCESS";
export const USER_RECOVER_PASSWORD_FAILED = "USER_RECOVER_PASSWORD_FAILED";

export const CONTACTS_REQUESTED = "CONTACTS_REQUESTED";
export const CONTACTS_SUCCESS = "CONTACTS_SUCCESS";
export const CONTACTS_FAILED = "CONTACTS_FAILED";

export const GET_GROUPS_REQUESTED = "GROUPS_REQUESTED";
export const GET_GROUPS_SUCCESS = "GROUPS_SUCCESS";
export const GET_GROUPS_FAILED = "GROUPS_FAILED";

export const GET_MESSAGES_REQUESTED = "GET_MESSAGES_REQUESTED";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILED = "GET_MESSAGES_FAILED";

export const SEND_MESSAGES_REQUESTED = "SEND_MESSAGES_REQUESTED";
export const SEND_MESSAGES_SUCCESS = "SEND_MESSAGES_SUCCESS";
export const SEND_MESSAGES_FAILED = "SEND_MESSAGES_FAILED";

export const SET_NEW_MESSAGE_REQUESTED = "SET_NEW_MESSAGE_REQUESTED";
export const SET_NEW_MESSAGE_SUCCESS = "SET_NEW_MESSAGE_SUCCESS";

export const CLEAR_MESSAGES_STORE_REQUESTED = "CLEAR_MESSAGES_STORE_REQUESTED";
export const CLEAR_MESSAGES_STORE_SUCCESS = "CLEAR_MESSAGES_STORE_SUCCESS";
export const CLEAR_MESSAGES_STORE_FAILED = "CLEAR_MESSAGES_STORE_FAILED";

export const CHANGE_SEARCH_CHANNELS_INPUT_REQUESTED =
  "CHANGE_SEARCH_CHANNELS_INPUT_REQUESTED";
export const CHANGE_SEARCH_CHANNELS_INPUT_SUCCESS =
  "CHANGE_SEARCH_CHANNELS_INPUT_SUCCESS";

export const GET_CHAT_CHANNELS_REQUESTED = "GET_CHAT_CHANNELS_REQUESTED";
export const GET_CHAT_CHANNELS_SUCCESS = "GET_CHAT_CHANNELS_SUCCESS";
export const GET_CHAT_CHANNELS_FAILED = "GET_CHAT_CHANNELS_FAILED";

export const GET_CODES_REQUESTED = "GET_CODES_REQUESTED";
export const GET_CODES_SUCCESS = "GET_CODES_SUCCESS";
export const GET_CODES_FAILED = "GET_CODES_FAILED";

export const GET_CODE_BY_URL_REQUESTED = "GET_CODE_BY_URL_REQUESTED";
export const GET_CODE_BY_URL_SUCCESS = "GET_CODE_BY_URL_SUCCESS";
export const GET_CODE_BY_URL_FAILED = "GET_CODE_BY_URL_FAILED";

export const SHARE_PUBLIC_CODE_REQUESTED = "SHARE_PUBLIC_CODE_REQUESTED";
export const SHARE_PUBLIC_CODE_SUCCESS = "SHARE_PUBLIC_CODE_SUCCESS";
export const SHARE_PUBLIC_CODE_FAILED = "SHARE_PUBLIC_CODE_FAILED";

export const UPDATE_CODE_REQUESTED = "UPDATE_CODE_REQUESTED";
export const UPDATE_CODE_SUCCESS = "UPDATE_CODE_SUCCESS";
export const UPDATE_CODE_FAILED = "UPDATE_CODE_FAILED";

export const CREATE_CODE_REQUESTED = "CREATE_CODE_REQUESTED";
export const CREATE_CODE_SUCCESS = "CREATE_CODE_SUCCESS";
export const CREATE_CODE_FAILED = "CREATE_CODE_FAILED";

export const CLEAR_CURRENT_CODE_REQUESTED = "CLEAR_CURRENT_CODE_REQUESTED";
export const CLEAR_CURRENT_CODE_SUCCESS = "CLEAR_CURRENT_CODE_SUCCESS";

export const GET_PROJECT_REQUESTED = "GET_PROJECT_REQUESTED";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILED = "GET_PROJECT_FAILED";

export const SET_PROJECT_BOARD_REQUESTED = "SET_PROJECT_BOARD_REQUESTED";
export const SET_PROJECT_BOARD_SUCCESS = "SET_PROJECT_BOARD_SUCCESS";

export const GET_PROJECTS_REQUESTED = "GET_PROJECTS_REQUESTED";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILED = "GET_PROJECTS_FAILED";

export const UPDATE_PROJECT_REQUESTED = "UPDATE_PROJECT_REQUESTED";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILED = "UPDATE_PROJECT_FAILED";

export const CREATE_PROJECT_REQUESTED = "CREATE_PROJECT_REQUESTED";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILED = "CREATE_PROJECT_FAILED";

export const DELETE_PROJECT_REQUESTED = "DELETE_PROJECT_REQUESTED";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILED = "DELETE_PROJECT_FAILED";

export const GET_PROJECT_NOTIFICATIONS_REQUESTED =
  "GET_PROJECT_NOTIFICATIONS_REQUESTED";
export const GET_PROJECT_NOTIFICATIONS_SUCCESS =
  "GET_PROJECT_NOTIFICATIONS_SUCCESS";
export const GET_PROJECT_NOTIFICATIONS_FAILED =
  "GET_PROJECT_NOTIFICATIONS_FAILED";

export const CREATE_PROJECT_NOTIFICATIONS_REQUESTED =
  "CREATE_PROJECT_NOTIFICATIONS_REQUESTED";
export const CREATE_PROJECT_NOTIFICATIONS_SUCCESS =
  "CREATE_PROJECT_NOTIFICATIONS_SUCCESS";
export const CREATE_PROJECT_NOTIFICATIONS_FAILED =
  "CREATE_PROJECT_NOTIFICATIONS_FAILED";

export const UPDATE_PROJECT_NOTIFICATIONS_REQUESTED =
  "UPDATE_PROJECT_NOTIFICATIONS_REQUESTED";
export const UPDATE_PROJECT_NOTIFICATIONS_FAILED =
  "UPDATE_PROJECT_NOTIFICATIONS_FAILED";
export const UPDATE_PROJECT_NOTIFICATIONS_SUCCESS =
  "UPDATE_PROJECT_NOTIFICATIONS_SUCCESS";

export const ADD_USER_TO_PROJECT_REQUESTED = "ADD_USER_TO_PROJECT_REQUESTED";
export const ADD_USER_TO_PROJECT_SUCCESS = "ADD_USER_TO_PROJECT_SUCCESS";
export const ADD_USER_TO_PROJECT_FAILED = "ADD_USER_TO_PROJECT_FAILED";

export const DELETE_USER_FROM_PROJECT_REQUESTED =
  "DELETE_USER_FROM_PROJECT_REQUESTED";
export const DELETE_USER_FROM_PROJECT_SUCCESS =
  "DELETE_USER_FROM_PROJECT_SUCCESS";
export const DELETE_USER_FROM_PROJECT_FAILED =
  " DELETE_USER_FROM_PROJECT_FAILED";

export const UPDATE_USERS_PROJECT_REQUESTED = "UPDATE_USERS_PROJECT_REQUESTED";
export const UPDATE_USERS_PROJECT_SUCCESS = "UPDATE_USERS_PROJECT_SUCCESS";

export const CLEAR_CURRENT_PROJECT_REQUESTED =
  "CLEAR_CURRENT_PROJECT_REQUESTED";
export const CLEAR_CURRENT_PROJECT_SUCCESS = "CLEAR_CURRENT_PROJECT_SUCCESS";

export const SET_PROJECT_NOTIFICATIONS_REQUESTED =
  "SET_PROJECT_NOTIFICATIONS_REQUESTED";
export const SET_PROJECT_NOTIFICATIONS_SUCCESS =
  "SET_PROJECT_NOTIFICATIONS_SUCCESS";

export const GET_BOARD_REQUESTED = "GET_BOARD_REQUESTED";
export const GET_BOARD_SUCCESS = "GET_BOARD_SUCCESS";
export const GET_BOARD_FAILED = "GET_BOARD_FAILED";

export const SET_BOARD_REQUESTED = "SET_BOARD_REQUESTED";
export const SET_BOARD_SUCCESS = "SET_BOARD_SUCCESS";

export const CLEAR_BOARD_REQUESTED = "CLEAR_BOARD_REQUESTED";
export const CLEAR_BOARD_SUCCESS = "CLEAR_BOARD_SUCCESS";

export const UPDATE_BOARD_REQUESTED = "UPDATE_BOARD_REQUESTED";
export const UPDATE_BOARD_SUCCESS = "UPDATE_BOARD_SUCCESS";
export const UPDATE_BOARD_FAILED = "UPDATE_BOARD_FAILED";

export const CREATE_BOARD_REQUESTED = "CREATE_BOARD_REQUESTED";
export const CREATE_BOARD_SUCCESS = "CREATE_BOARD_SUCCESS";
export const CREATE_BOARD_FAILED = "CREATE_BOARD_FAILED";

export const GET_COLUMN_REQUESTED = "GET_COLUMN_REQUESTED";
export const GET_COLUMN_SUCCESS = "GET_COLUMN_SUCCESS";
export const GET_COLUMN_FAILED = "GET_COLUMN_FAILED";

export const SET_COLUMNS_REQUESTED = "SET_COLUMNS_REQUESTED";
export const SET_COLUMNS_SUCCESS = "SET_COLUMNS_SUCCESS";

export const UPDATE_COLUMNS_STATE_REQUESTED = "UPDATE_COLUMNS_STATE_REQUESTED";
export const UPDATE_COLUMNS_STATE_SUCCESS = "UPDATE_COLUMNS_STATE_SUCCESS";

export const UPDATE_COLUMN_REQUESTED = "UPDATE_COLUMN_REQUESTED";
export const UPDATE_COLUMN_SUCCESS = "UPDATE_COLUMN_SUCCESS";
export const UPDATE_COLUMN_FAILED = "UPDATE_COLUMN_FAILED";

export const CREATE_COLUMN_REQUESTED = "CREATE_COLUMN_REQUESTED";
export const CREATE_COLUMN_SUCCESS = "CREATE_COLUMN_SUCCESS";
export const CREATE_COLUMN_FAILED = "CREATE_COLUMN_FAILED";

export const DELETE_COLUMN_REQUESTED = "DELETE_COLUMN_REQUESTED";
export const DELETE_COLUMN_SUCCESS = "DELETE_COLUMN_SUCCESS";
export const DELETE_COLUMN_FAILED = "DELETE_COLUMN_FAILED";

export const CLEAR_COLUMN_REQUESTED = "CLEAR_COLUMN_REQUESTED";
export const CLEAR_COLUMN_SUCCESS = "CLEAR_COLUMN_SUCCESS";

export const GET_CARD_REQUESTED = "GET_CARD_REQUESTED";
export const GET_CARD_SUCCESS = "GET_CARD_SUCCESS";
export const GET_CARD_FAILED = "GET_CARD_FAILED";

export const GET_CARDS_REQUESTED = "GET_CARDS_REQUESTED";
export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS";
export const GET_CARDS_FAILED = "GET_CARDS_FAILED";

export const SET_CARDS_REQUESTED = "SET_CARDS_REQUESTED";
export const SET_CARDS_SUCCESS = "SET_CARDS_SUCCESS";

export const UPDATE_CARD_REQUESTED = "UPDATE_CARD_REQUESTED";
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS";
export const UPDATE_CARD_FAILED = "UPDATE_CARD_FAILED";

export const CREATE_CARD_REQUESTED = "CREATE_CARD_REQUESTED";
export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS";
export const CREATE_CARD_FAILED = "CREATE_CARD_FAILED";

export const DELETE_CARD_REQUESTED = "DELETE_CARD_REQUESTED";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILED = "DELETE_CARD_FAILED";

export const DELETE_ALL_CARDS_REQUESTED = "DELETE_ALL_CARDS_REQUESTED";
export const DELETE_ALL_CARDS_SUCCESS = "DELETE_ALL_CARDS_SUCCESS";
export const DELETE_ALL_CARDS_FAILED = "DELETE_ALL_CARDS_FAILED";

export const CLOSE_CARD_REQUESTED = "CLOSE_CARD_REQUESTED";
export const CLOSE_CARD_SUCCESS = "CLOSE_CARD_SUCCESS";
export const CLOSE_CARD_FAILED = "CLOSE_CARD_FAILED";

export const REMOVE_CARD_REQUESTED = "REMOVE_CARD_REQUESTED";
export const REMOVE_CARD_SUCCESS = "REMOVE_CARD_SUCCESS";

export const CLEAR_CARDS_REQUESTED = "CLEAR_CARDS_REQUESTED";
export const CLEAR_CARDS_SUCCESS = "CLEAR_CARDS_SUCCESS";

export const CLEAR_CURRENT_CARD_REQUESTED = "CLEAR_CURRENT_CARD_REQUESTED";
export const CLEAR_CURRENT_CARD_SUCCESS = "CLEAR_CURRENT_CARD_SUCCESS";

export const GET_LABELS_REQUESTED = "GET_LABELS_REQUESTED";
export const GET_LABELS_SUCCESS = "GET_LABELS_SUCCESS";
export const GET_LABELS_FAILED = "GET_LABELS_FAILED";

export const SET_LABELS_REQUESTED = "SET_LABELS_REQUESTED";
export const SET_LABELS_SUCCESS = "SET_LABELS_SUCCESS";

export const UPDATE_LABEL_REQUESTED = "UPDATE_LABEL_REQUESTED";
export const UPDATE_LABEL_SUCCESS = "UPDATE_LABEL_SUCCESS";
export const UPDATE_LABEL_FAILED = "UPDATE_LABEL_FAILED";

export const CREATE_LABEL_REQUESTED = "CREATE_LABEL_REQUESTED";
export const CREATE_LABEL_SUCCESS = "CREATE_LABEL_SUCCESS";
export const CREATE_LABEL_FAILED = "CREATE_LABEL_FAILED";

export const DELETE_LABEL_REQUESTED = "DELETE_LABEL_REQUESTED";
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS";
export const DELETE_LABEL_FAILED = "DELETE_LABEL_FAILED";

export const DELETE_ALL_LABELS_REQUESTED = "DELETE_ALL_LABELS_REQUESTED";
export const DELETE_ALL_LABELS_SUCCESS = "DELETE_ALL_LABELS_SUCCESS";
export const DELETE_ALL_LABELS_FAILED = "DELETE_ALL_LABELS_FAILED";

export const CLEAR_LABEL_REQUESTED = "CLEAR_LABEL_REQUESTED";
export const CLEAR_LABEL_SUCCESS = "CLEAR_LABEL_SUCCESS";

export const GET_TASKS_REQUESTED = "GET_TASKS_REQUESTED";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_FAILED = "GET_TASKS_FAILED";

export const SET_TASKS_REQUESTED = "SET_TASKS_REQUESTED";
export const SET_TASKS_SUCCESS = "SET_TASKS_SUCCESS";

export const UPDATE_TASK_REQUESTED = "UPDATE_TASK_REQUESTED";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILED = "UPDATE_TASK_FAILED";

export const CREATE_TASK_REQUESTED = "CREATE_TASK_REQUESTED";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAILED = "CREATE_TASK_FAILED";

export const DELETE_TASK_REQUESTED = "DELETE_TASK_REQUESTED";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILED = "DELETE_TASK_FAILED";

export const DELETE_ALL_TASKS_REQUESTED = "DELETE_ALL_TASKS_REQUESTED";
export const DELETE_ALL_TASKS_SUCCESS = "DELETE_ALL_TASKS_SUCCESS";
export const DELETE_ALL_TASKS_FAILED = "DELETE_ALL_TASKS_FAILED";

export const CLEAR_TASK_REQUESTED = "CLEAR_TASK_REQUESTED";
export const CLEAR_TASK_SUCCESS = "CLEAR_TASK_SUCCESS";

export const GET_COMMENTS_REQUESTED = "GET_COMMENTS_REQUESTED";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_FAILED = "GET_COMMENTS_FAILED";

export const CREATE_COMMENT_REQUESTED = "CREATE_COMMENT_REQUESTED";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILED = "CREATE_COMMENT_FAILED";

export const UPDATE_COMMENT_REQUESTED = "UPDATE_COMMENT_REQUESTED";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED";

export const INCREMENT_MESSAGE_BADGE_REQUESTED =
  "INCREMENT_MESSAGE_BADGE_REQUESTED";
export const INCREMENT_MESSAGE_BADGE_SUCCESS =
  "INCREMENT_MESSAGE_BADGE_SUCCESS";
export const INCREMENT_MESSAGE_BADGE_FAILED = "INCREMENT_MESSAGE_BADGE_FAILED";

export const CLEAR_MESSAGE_BADGE_REQUESTED = "CLEAR_MESSAGE_BADGE_REQUESTED";
export const CLEAR_MESSAGE_BADGE_SUCCESS = "CLEAR_MESSAGE_BADGE_SUCCESS";
export const CLEAR_MESSAGE_BADGE_FAILED = "CLEAR_MESSAGE_BADGE_FAILED";

export const DELETE_COMMENT_REQUESTED = "DELETE_COMMENT_REQUESTED";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED";

export const GET_ROLES_REQUESTED = "GET_ROLES_REQUESTED";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILED = "GET_ROLES_FAILED";

export const CREATE_ROLE_REQUESTED = "CREATE_ROLE_REQUESTED";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILED = "CREATE_ROLE_FAILED";

export const DELETE_ROLE_REQUESTED = "DELETE_ROLE_REQUESTED";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED";

export const SET_PROJECT_ROLE_REQUESTED = "SET_PROJECT_ROLE_REQUESTED";
export const SET_PROJECT_ROLE_SUCCESS = "SET_PROJECT_ROLE_SUCCESS";
export const SET_PROJECT_ROLE_FAILED = "SET_PROJECT_ROLE_FAILED";

export const UPDATE_PROJECT_ROLE_REQUESTED = "UPDATE_PROJECT_ROLE_REQUESTED";
export const UPDATE_PROJECT_ROLE_SUCCESS = "UPDATE_PROJECT_ROLE_SUCCESS";
export const UPDATE_PROJECT_ROLE_FAILED = "UPDATE_PROJECT_ROLE_FAILED";
export const CHANGE_CURRENT_CHAT_REQUESTED = "CHANGE_CURRENT_CHAT_REQUESTED";
export const CHANGE_CURRENT_CHAT_SUCCESS = "CHANGE_CURRENT_CHAT_SUCCESS";
export const CHANGE_CURRENT_CHAT_FAILED = "CHANGE_CURRENT_CHAT_FAILED";

export const GET_CARD_HISTORY_REQUESTED = "GET_CARD_HISTORY_REQUESTED";
export const GET_CARD_HISTORY_SUCCESS = "GET_CARD_HISTORY_SUCCESS";
export const GET_CARD_HISTORY_FAILED = "GET_CARD_HISTORY_FAILED";

export const CLEAR_HISTORY_REQUESTED = "CLEAR_HISTORY_REQUESTED";
export const CLEAR_HISTORY_SUCCESS = "CLEAR_HISTORY_SUCCESS";

export const CREATE_CHAT_GROUP_REQUESTED = "CREATE_CHAT_GROUP_REQUESTED";
export const CREATE_CHAT_GROUP_SUCCESS = "CREATE_CHAT_GROUP_SUCCESS";
export const CREATE_CHAT_GROUP_FAILED = "CREATE_CHAT_GROUP_FAILED";

export const BACK_TO_CONTACTS_PAGE_REQUESTED = "BACK_TO_CONTACTS_PAGE_REQUESTED";
export const BACK_TO_CONTACTS_PAGE_SUCCESS = "BACK_TO_CONTACTS_PAGE_SUCCESS";
export const BACK_TO_CONTACTS_PAGE_FAILED = "BACK_TO_CONTACTS_PAGE_FAILED";
