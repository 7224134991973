import { IContactsState } from "@/features/chat/domain/interfaces/IContactsState";
import * as actions from "../types";
import { StateStructure } from "./domain/StateStructure";

const initialState: IContactsState = {
  contacts: [],
  loading: false,
  error: {},
};

export default function contacts(
  state = initialState,
  { type, payload }: StateStructure
) {
  switch (type) {
    case actions.CONTACTS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        contacts: [],
      };
    case actions.CONTACTS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        contacts: payload,
      };
    case actions.CONTACTS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.INCREMENT_MESSAGE_BADGE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.INCREMENT_MESSAGE_BADGE_SUCCESS:
      let newState = state.contacts;
      if (payload.id) {
        newState = state.contacts.map((contact) => {
          if (contact._id === payload.id) {
            contact.unread = contact.unread ? contact.unread + 1 : 1;
          }
          return contact;
        });
      }
      return {
        ...initialState,
        ...state,
        loading: false,
        contacts: newState,
      };

    case actions.CLEAR_MESSAGE_BADGE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CLEAR_MESSAGE_BADGE_SUCCESS:
      let currentContacts = state.contacts;
      if (payload.id) {
        currentContacts = state.contacts.map((contact) => {
          if (contact._id === payload.id) {
            contact.unread = 0;
          }
          return contact;
        });
      }
      return {
        ...initialState,
        ...state,
        loading: false,
        contacts: currentContacts,
      };
    default:
      return state;
  }
}
