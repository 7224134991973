const formatDate = (value: Date | string): string => {
  if (!value) return "";
  const date = new Date(value);
  if (!date) return "";

  const months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
  const day = date.getDate();
  const month = months[date.getMonth()];
  return day + " " + month;
};
const colorsList: string[] = ["#a8193d", "#4fcc25", "#1ebffa", "#8da377", "#9975bd", "#cf61a1", "#240959"];

function isEqual(object1: Object, object2: Object) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}
function hasSameValues(object1: Object, object2: Object) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  for (let key of keys1.filter((key) => keys2.includes(key))) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

export { isEqual, hasSameValues, formatDate, colorsList };
