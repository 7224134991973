import { all } from "redux-saga/effects";
import userSaga from "./userSaga";
import contactsSaga from "./contactsSaga";
import codesSaga from "./codesSaga";
import projectSaga from "./kanban/projectSaga";
import boardSaga from "./kanban/boardSaga";
import columnSaga from "./kanban/columnSaga";
import labelSaga from "./kanban/labelSaga";
import taskSaga from "./kanban/taskSaga";
import commentSaga from "./kanban/commentSaga";
import rolesSaga from "./Roles/rolesSaga";
import notificationsSaga from "./kanban/notificationSaga";
import historySaga from "./kanban/historySaga";
import { channelsSaga, messagesSaga } from "./chat/index";

export default function* rootSaga() {
  yield all([
    userSaga(),
    contactsSaga(),
    channelsSaga(),
    messagesSaga(),
    codesSaga(),
    projectSaga(),
    boardSaga(),
    columnSaga(),
    labelSaga(),
    taskSaga(),
    commentSaga(),
    rolesSaga(),
    notificationsSaga(),
    historySaga(),
  ]);
}
