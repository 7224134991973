import { call, put, takeEvery } from "redux-saga/effects";
import TaskServices from "../../../services/kanban/task";
import * as type from "../../types";

function* setTasks(payload) {
  yield put({ type: type.SET_TASKS_SUCCESS, payload: payload.payload });
}

function* createTask(payload) {
  try {
    const { data } = yield call(TaskServices.createTask, payload);
    yield put({ type: type.CREATE_TASK_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.CREATE_TASK_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* updateTask(payload) {
  try {
    const { data } = yield call(TaskServices.updateTask, payload);
    yield put({ type: type.UPDATE_TASK_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.UPDATE_TASK_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* deleteTask(payload) {
  try {
    const { data } = yield call(TaskServices.deleteTask, payload);
    yield put({
      type: type.DELETE_TASK_SUCCESS,
      payload: { data, taskId: payload.payload.taskId },
    });
  } catch (error) {
    yield put({
      type: type.DELETE_TASK_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* taskSaga() {
  yield takeEvery(type.SET_TASKS_REQUESTED, setTasks);
  yield takeEvery(type.CREATE_TASK_REQUESTED, createTask);
  yield takeEvery(type.UPDATE_TASK_REQUESTED, updateTask);
  yield takeEvery(type.DELETE_TASK_REQUESTED, deleteTask);
}

export default taskSaga;
