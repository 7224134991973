import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

function createApiInstance(configs: AxiosRequestConfig<object>): AxiosInstance {
  const apiInstance: AxiosInstance = axios.create({
    timeout: 40000,
    ...configs,
  });
  return apiInstance;
}

export default createApiInstance;
