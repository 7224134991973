import { ServiceStructure } from "@/app/structures/ServiceStructure";
import { ChannelModel } from "../domain/models/ChannelModel";
import { AxiosResponse } from "axios";
import ApiClient from "@/services/ApiClient";
import { API_ROUTES, HOST_NAMES } from "@/constants";

export interface GetChatChannelsServiceParams {
  name?: string;
}

export interface GetChatChannelsServiceResponse {
  data: {
    groups: ChannelModel[];
    users: ChannelModel[];
  };
}

export class GetChatChannelsService
  implements ServiceStructure<GetChatChannelsServiceParams, GetChatChannelsServiceResponse>
{
  async handle(params: GetChatChannelsServiceParams): Promise<AxiosResponse<GetChatChannelsServiceResponse, any>> {
    return await new ApiClient(HOST_NAMES.CHAT).get(API_ROUTES.CHAT.CHANNELS, {
      params: { name: params.name },
    });
  }
}
