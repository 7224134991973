import { call, put, takeEvery } from "redux-saga/effects";
import ProjectServices from "../../../services/kanban/project";
import * as type from "../../types";
import { ToastHandler } from "@/features/toast/ToastHandler";

function* getProject(payload) {
  try {
    const { data } = yield call(ProjectServices.getProject, payload);
    yield put({ type: type.GET_PROJECT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.GET_PROJECT_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* getProjects(payload) {
  try {
    const { data } = yield call(ProjectServices.getProjects, payload);
    yield put({ type: type.GET_PROJECTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.GET_PROJECTS_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* createProject(payload) {
  try {
    const { data } = yield call(ProjectServices.createProject, payload);
    yield put({ type: type.CREATE_PROJECT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.CREATE_PROJECT_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* deleteProject(payload) {
  try {
    yield call(ProjectServices.deleteProject, payload);
    ToastHandler.success("Project Successfully deleted");
    yield put({ type: type.DELETE_PROJECT_SUCCESS, payload: payload });
  } catch (error) {
    yield put({
      type: type.DELETE_PROJECT_FAILED,
      payload: { message: error?.response?.data ?? error.message.message },
    });
  }
}

function* addUserToProject(payload) {
  try {
    const { data } = yield call(ProjectServices.addUserToProject, payload);
    yield put({ type: type.ADD_USER_TO_PROJECT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.ADD_USER_TO_PROJECT_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* deleteUserFromProject(payload) {
  try {
    const { data } = yield call(ProjectServices.deleteUserFromProject, payload);
    yield put({ type: type.DELETE_USER_FROM_PROJECT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.DELETE_USER_FROM_PROJECT_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* updateProject(payload) {
  try {
    const { data } = yield call(ProjectServices.updateProject, payload);
    yield put({ type: type.UPDATE_PROJECT_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.UPDATE_PROJECT_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* setProjectRole(payload) {
  try {
    const { data } = yield call(ProjectServices.setProjectRole, payload);
    yield put({ type: type.SET_PROJECT_ROLE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.SET_PROJECT_ROLE_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* updateProjectRole(payload) {
  try {
    const { data } = yield call(ProjectServices.updateProjectRole, payload);
    yield put({ type: type.UPDATE_PROJECT_ROLE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.UPDATE_PROJECT_ROLE_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* projectSaga() {
  yield takeEvery(type.GET_PROJECT_REQUESTED, getProject);
  yield takeEvery(type.GET_PROJECTS_REQUESTED, getProjects);
  yield takeEvery(type.CREATE_PROJECT_REQUESTED, createProject);
  yield takeEvery(type.DELETE_PROJECT_REQUESTED, deleteProject);
  yield takeEvery(type.ADD_USER_TO_PROJECT_REQUESTED, addUserToProject);
  yield takeEvery(type.DELETE_USER_FROM_PROJECT_REQUESTED, deleteUserFromProject);
  yield takeEvery(type.UPDATE_PROJECT_REQUESTED, updateProject);
  yield takeEvery(type.SET_PROJECT_ROLE_REQUESTED, setProjectRole);
  yield takeEvery(type.UPDATE_PROJECT_ROLE_REQUESTED, updateProjectRole);
}

export default projectSaga;
