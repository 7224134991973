import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class LabelServices {
  static async createLabel(data) {
    return await new ApiClient(HOST_NAMES.KANBAN).post(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/labels`,
      { color: data.payload.color, text: data.payload.text }
    );
  }

  static async deleteLabel(data) {
    return await new ApiClient(HOST_NAMES.KANBAN).delete(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/labels/${data.payload.labelId}`
    );
  }
}
