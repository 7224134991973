import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class RolesServices{
    static async getRoles(data){
        return await new ApiClient(HOST_NAMES.USER).get(`${API_ROUTES.USER.ROLES}?name=${data.payload?.name ?? "undefined"}`);
    }

    static async createRole(data){
        return await new ApiClient(HOST_NAMES.USER).post(`${API_ROUTES.USER.ROLES}`, {name: data.payload.name, type: data.payload.type});
    } 

    static async deleteRole(data){
        return await new ApiClient(HOST_NAMES.USER).delete(`${API_ROUTES.USER.ROLES}/${data.payload.roleId}`,);
    } 
}