import { IMessageState } from "@/features/chat/domain/interfaces/IMessageState";
import * as actions from "../../types";
import { StateStructure } from "../domain/StateStructure";
import { hasSameValues } from "@/helper/Util";

const initialState: IMessageState = {
  currentChannelType: undefined,
  currentChannel: undefined,
  message: null,
  messages: [],
  sentMessage: null,
  loading: false,
  isMessageSent: false,
  error: {},
};

export default function messages(state = initialState, { type, payload }: StateStructure): IMessageState {
  state.isMessageSent = false;
  switch (type) {
    case actions.GET_MESSAGES_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.GET_MESSAGES_SUCCESS:
      const newMessages = state.messages;
      newMessages.unshift(...payload);
      return {
        ...initialState,
        ...state,
        loading: false,
        messages: newMessages,
      };
    case actions.GET_MESSAGES_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.SEND_MESSAGES_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        sentMessage: payload,
      };
    case actions.SEND_MESSAGES_SUCCESS:
      return {
        ...initialState,
        ...state,
        sentMessage: payload,
        loading: false,
        isMessageSent: true,
      };
    case actions.SEND_MESSAGES_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.SET_NEW_MESSAGE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        message: payload,
      };
    case actions.SET_NEW_MESSAGE_SUCCESS:
      const messages = [...state.messages];

      if (state.messages.findIndex((message) => hasSameValues(payload.payload, message)) === -1) {
        messages.push(payload.payload);
      }
      return {
        ...initialState,
        ...state,
        messages: messages,
        loading: false,
      };
    case actions.CLEAR_MESSAGES_STORE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CLEAR_MESSAGES_STORE_SUCCESS:
      return { ...initialState, ...state, loading: true, messages: [] };
    case actions.CHANGE_CURRENT_CHAT_REQUESTED:
      return {
        ...initialState,
        ...state,
      };
    case actions.CHANGE_CURRENT_CHAT_SUCCESS:
      return {
        ...initialState,
        ...state,
        currentChannel: payload.payload.channel,
        currentChannelType: payload.payload.type,
      };
    case actions.BACK_TO_CONTACTS_PAGE_REQUESTED:
      return {
        ...initialState,
        ...state,
      };
    case actions.BACK_TO_CONTACTS_PAGE_SUCCESS:
      return {
        ...initialState,
        ...state,
        currentChannel: undefined,
        currentChannelType: undefined,
      };
    case actions.CHANGE_SEARCH_CHANNELS_INPUT_REQUESTED:
      return {
        ...initialState,
        ...state,
      };
    default:
      return state;
  }
}
