import { BaseAction } from "@store/domain/Action";
import * as type from "@store/types";

interface UserLogoutPayload {
  id: string;
}
interface UserLogoutAction extends BaseAction {
  payload: UserLogoutPayload;
}
export function userLogout(payload: UserLogoutPayload): UserLogoutAction {
  return {
    type: type.USER_LOGOUT_REQUESTED,
    payload,
  };
}
