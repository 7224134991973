import { call, put, takeEvery } from "redux-saga/effects";
import { UserInSession } from "../../api/getSession";
import UsersService from "../../services/Users";
import * as type from "../types";
import Cookies from "universal-cookie";
import { subscribedGroups } from "@chat/domain/interfaces/IGroup";

function* authenticateUser(payload) {
  try {
    const { data } = yield call(UsersService.login, payload);
    const cookies = new Cookies();
    cookies.set("userId", data.user._id, { path: "/" });
    cookies.set("accessToken", data.user.accessToken, { path: "/" });
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem(subscribedGroups, JSON.stringify(data.groups));

    yield put({ type: type.USER_LOGIN_SUCCESS, payload: data.user });
  } catch (error) {
    yield put({ type: type.USER_LOGIN_FAILED, payload: { message: error.response.data } });
  }
}

function* updateUser(payload) {
  try {
    const { data } = yield call(UsersService.updateUser, payload);
    localStorage.setItem("user", JSON.stringify(data.user));
    yield put({ type: type.USER_UPDATE_SUCCESS, payload: data.user });
  } catch (error) {
    console.log(error);
    yield put({ type: type.USER_UPDATE_FAILED, payload: { message: error.response.data } });
  }
}

function* register(payload) {
  try {
    const { data } = yield call(UsersService.register, payload);
    localStorage.setItem("user", JSON.stringify(data.user));
    yield put({ type: type.USER_REGISTER_SUCCESS, payload: data.user });
  } catch (error) {
    yield put({ type: type.USER_REGISTER_FAILED, payload: { error: error.response.data } });
  }
}

function* recoverPassword(payload) {
  try {
    const { data } = yield call(UsersService.recoverPassword, payload);
    yield put({ type: type.USER_RECOVER_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    yield put({ type: type.USER_RECOVER_PASSWORD_FAILED, payload: { error: error.response.data } });
  }
}

function* logout(payload) {
  try {
    yield call(UsersService.logout, payload);
    localStorage.removeItem("user");
    const cookies = new Cookies();
    cookies.remove("userId", { path: "/" });
    yield put({ type: type.USER_LOGOUT_SUCCESS, payload: {} });
  } catch (error) {
    yield put({ type: type.USER_LOGOUT_FAILED, payload: { message: error.response.data } });
  }
}

function* retrieveUserInSession(payload) {
  yield put({ type: type.USER_RETRIEVE_SESSION_SUCCESS, payload: payload.payload.user });
}

function* setUserAvatar(payload) {
  try {
    const { data } = yield call(UsersService.setAvatar, payload);

    const user = UserInSession;

    if (user._id) {
      user.isAvatarImageSet = true;
      user.avatarUrl = data.image;
      localStorage.setItem("user", JSON.stringify(user));
    }

    yield put({ type: type.USER_SET_AVATAR_SUCCESS, payload: UserInSession.image });
  } catch (error) {
    yield put({ type: type.USER_SET_AVATAR_FAILED, payload: { message: error.response.data } });
  }
}
function* userSaga() {
  yield takeEvery(type.USER_LOGIN_REQUESTED, authenticateUser);
  yield takeEvery(type.USER_REGISTER_REQUESTED, register);
  yield takeEvery(type.USER_RECOVER_PASSWORD_REQUESTED, recoverPassword);
  yield takeEvery(type.USER_LOGOUT_REQUESTED, logout);
  yield takeEvery(type.USER_RETRIEVE_SESSION_REQUESTED, retrieveUserInSession);
  yield takeEvery(type.USER_SET_AVATAR_REQUESTED, setUserAvatar);
  yield takeEvery(type.USER_UPDATE_REQUESTED, updateUser);
}

export default userSaga;
