import { call, put, takeEvery } from "redux-saga/effects";
import CardServices from "../../../services/kanban/card";
import ColumnServices from "../../../services/kanban/column";
import * as type from "../../types";

function* createColumn(payload) {
  try {
    const { data } = yield call(ColumnServices.createColumn, payload);
    yield put({ type: type.CREATE_COLUMN_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.CREATE_COLUMN_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* updateColumn(payload) {
  try {
    const { data } = yield call(ColumnServices.updateColumn, payload);
    yield put({ type: type.UPDATE_COLUMN_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.UPDATE_COLUMN_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* deleteColumn(payload) {
  try {
    const { data } = yield call(ColumnServices.deleteColumn, payload);
    yield put({
      type: type.DELETE_COLUMN_SUCCESS,
      payload: { data, columnId: payload.payload.columnId },
    });
  } catch (error) {
    yield put({
      type: type.DELETE_COLUMN_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* getCard(payload) {
  try {
    const { data } = yield call(CardServices.getCard, payload);
    yield put({ type: type.GET_CARD_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.GET_CARD_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* updateCard(payload) {
  try {
    const { data } = yield call(CardServices.updateCard, payload);
    yield put({ type: type.UPDATE_CARD_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.UPDATE_CARD_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* createCard(payload) {
  try {
    const { data } = yield call(CardServices.createCard, payload);
    yield put({ type: type.CREATE_CARD_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.CREATE_CARD_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* deleteCard(payload) {
  try {
    const { data } = yield call(CardServices.deleteCard, payload);
    yield put({
      type: type.DELETE_CARD_SUCCESS,
      payload: { data, cardId: payload.payload.cardId },
    });
  } catch (error) {
    yield put({
      type: type.DELETE_CARD_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* clearCurrentCard() {
  yield put({ type: type.CLEAR_CURRENT_CARD_SUCCESS, payload: {} });
}

function* setColumns(payload) {
  yield put({ type: type.SET_COLUMNS_SUCCESS, payload: payload.payload });
}

function* updateColumnsState(payload) {
  yield put({ type: type.UPDATE_COLUMNS_STATE_SUCCESS, payload: payload.payload });
}

function* removeCard(payload) {
  yield put({ type: type.REMOVE_CARD_SUCCESS, payload: payload.payload });
}

function* columnSaga() {
  yield takeEvery(type.CREATE_COLUMN_REQUESTED, createColumn);
  yield takeEvery(type.DELETE_COLUMN_REQUESTED, deleteColumn);
  yield takeEvery(type.UPDATE_COLUMN_REQUESTED, updateColumn);
  yield takeEvery(type.SET_COLUMNS_REQUESTED, setColumns);
  yield takeEvery(type.UPDATE_COLUMNS_STATE_REQUESTED, updateColumnsState);
  yield takeEvery(type.GET_CARD_REQUESTED, getCard);
  yield takeEvery(type.UPDATE_CARD_REQUESTED, updateCard);
  yield takeEvery(type.CREATE_CARD_REQUESTED, createCard);
  yield takeEvery(type.DELETE_CARD_REQUESTED, deleteCard);
  yield takeEvery(type.CLEAR_CURRENT_CARD_REQUESTED, clearCurrentCard);
  yield takeEvery(type.REMOVE_CARD_REQUESTED, removeCard);
}

export default columnSaga;
