import { call, put, takeEvery } from "redux-saga/effects";
import BoardServices from "@services/kanban/board";
import * as type from "../../types";

function* createBoard(payload) {
  try {
    const { data } = yield call(BoardServices.createBoard, payload);
    yield put({ type: type.CREATE_BOARD_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.CREATE_BOARD_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* closeCard(payload) {
  try {
    const { data } = yield call(BoardServices.closeCard, payload);
    yield put({ type: type.CLOSE_CARD_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.CLOSE_CARD_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* setBoard(payload) {
  yield put({ type: type.SET_BOARD_SUCCESS, payload: payload.payload });
}

function* boardSaga() {
  yield takeEvery(type.CLOSE_CARD_REQUESTED, closeCard);
  yield takeEvery(type.SET_BOARD_REQUESTED, setBoard);
  yield takeEvery(type.CREATE_BOARD_REQUESTED, createBoard);
}

export default boardSaga;
