import { call, put, takeEvery } from "redux-saga/effects";
import { GetChatChannelsPayload } from "../domain/messagesSaga";
import * as type from "../../types";
import {
  GetChatChannelsService,
  GetChatChannelsServiceResponse,
} from "@/features/chat/services";
import { CreateChatGroupPayload } from "../domain/messagesSaga/CreateChatGroupPayload";
import {
  CreateChatGroupService,
  CreateChatGroupServiceResponse,
} from "@/features/chat/services/CreateChatGroupService";

function* getChatChannels({ payload }: GetChatChannelsPayload) {
  try {
    const { data }: GetChatChannelsServiceResponse = yield call(
      new GetChatChannelsService().handle,
      payload
    );

    yield put({ type: type.GET_CHAT_CHANNELS_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.GET_CHAT_CHANNELS_FAILED,
      payload: { message: error },
    });
  }
}

function* createChatGroup({ payload }: CreateChatGroupPayload) {
  try {
    const { data }: CreateChatGroupServiceResponse = yield call(
      new CreateChatGroupService().handle,
      payload
    );

    yield put({ type: type.CREATE_CHAT_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.CREATE_CHAT_GROUP_FAILED,
      payload: { message: error },
    });
  }
}

function* channelsSaga() {
  yield takeEvery(type.GET_CHAT_CHANNELS_REQUESTED, getChatChannels);
  yield takeEvery(type.CREATE_CHAT_GROUP_REQUESTED, createChatGroup);
}

export { channelsSaga };
