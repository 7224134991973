import createApiInstance from "./ApiInstance";

import { API_HOSTS } from "../constants";
import Cookies from "universal-cookie";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { UserInSession } from "../api/getSession";

export default class ApiClient {
  apiInstance: AxiosInstance;

  constructor(hostName: string) {
    const hostUrl: string = API_HOSTS.filter((host) => host.name === hostName)[0].url;
    this.apiInstance = createApiInstance({ baseURL: hostUrl });
    const cookies: Cookies = new Cookies();

    this.apiInstance.interceptors.request.use(
      function (config: AxiosRequestConfig<object>): AxiosRequestConfig<object> {
        config.headers.Authorization = cookies.get("accessToken")
          ? cookies.get("accessToken")
          : UserInSession
          ? UserInSession.accessToken
          : "";
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  get(endpont: string, config: AxiosRequestConfig<object> = {}): Promise<AxiosResponse<any, any>> {
    return this.apiInstance.get(endpont, {
      ...config,
    });
  }

  post(endpont: string, body: object = {}, config: AxiosRequestConfig<object> = {}): Promise<AxiosResponse<any, any>> {
    return this.apiInstance.post(endpont, body, {
      ...config,
    });
  }

  put(endpoint: string, body: object, config: AxiosRequestConfig<object> = {}): Promise<AxiosResponse<any, any>> {
    return this.apiInstance.put(endpoint, body, {
      ...config,
    });
  }

  patch(endpoint: string, body: object, config: AxiosRequestConfig<object> = {}): Promise<AxiosResponse<any, any>> {
    return this.apiInstance.patch(endpoint, body, {
      ...config,
    });
  }

  postData(
    endpont: string,
    formData: FormData,
    config: AxiosRequestConfig<object> = {}
  ): Promise<AxiosResponse<any, any>> {
    return this.apiInstance.post(endpont, formData, {
      ...{ "Content-Type": "multipart/form-data" },
      ...config,
    });
  }

  delete(endpont: string, config: AxiosRequestConfig<object> = {}): Promise<AxiosResponse<any, any>> {
    return this.apiInstance.delete(endpont, {
      ...config,
    });
  }
}
