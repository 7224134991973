import ApiClient from "@services/ApiClient";
import { API_ROUTES, HOST_NAMES } from "@/constants";
import { AxiosResponse } from "axios";
import { ICode } from "../domain/Code";
export interface UpdateCodeServiceResponse {
  data: { code: ICode };
}

export class UpdateCodeService {
  static async handle(payload: ICode): Promise<AxiosResponse<UpdateCodeServiceResponse, any>> {
    return await new ApiClient(HOST_NAMES.CODEPEN).put(
      `${API_ROUTES.CODEPEN.MAIN_ROUTE}/${payload.id}`,
      payload
    );
  }
}
