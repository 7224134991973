import { lazy, ReactElement, Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Loading from "@meet/components/Loading";
import { useIsAuthenticated, verifyIfTokenIsExpired } from "./hooks/useIsAuthenticated";

import NavBar from "@features/AppBar";
import ErrorBoundary from "./helper/ErrorBoundary";

const MainScreenPage = lazy(() => import("@mainScreen/pages/MainScreenPage"));
const Chat = lazy(() => import("@chat/pages/ChatPage"));
const CodepenPage = lazy(() => import("@codepen/pages/CodepenPage"));
const CodeListPage = lazy(() => import("@codepen/pages/CodeListPage"));
const Login = lazy(() => import("@login/pages/Login"));
const RecoverPassword = lazy(() => import("@login/pages/RecoverPassword"));
const Home = lazy(() => import("@meet/pages/home"));
const Room = lazy(() => import("@meet/pages/room"));
const DashboardPage = lazy(() => import("@kanban/pages/DashboardPage"));
const ProjectSettingsPage = lazy(() => import("@kanban/pages/ProjectSettings/ProjectSettingsPage"));
const KanbanListPage = lazy(() => import("@kanban/pages/ProjectList"));
const SetupPage = lazy(() => import("@user/pages/setup"));
const Profile = lazy(() => import("@user/pages/profile/ProfilePage"));

const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  if (!useIsAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children;
};
const PublicRoute = ({ children }: { children: ReactElement }) => {
  verifyIfTokenIsExpired();

  return children;
};

export const AppRoutes = (): ReactElement => {
  return (
    <BrowserRouter>
      {verifyIfTokenIsExpired() && <NavBar />}
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/login" element={<PublicRoute children={<Login />} />} />
            <Route path="/room/:roomID" element={<Room />} />
            <Route path="/recover" element={<PublicRoute children={<RecoverPassword />} />} />
            <Route path="/setup" element={<ProtectedRoute children={<SetupPage />} />} />
            <Route path="/home" element={<ProtectedRoute children={<MainScreenPage />} />} />
            <Route path="/codePen" element={<ProtectedRoute children={<CodeListPage />} />} />
            <Route path="/profile" element={<ProtectedRoute children={<Profile />} />} />
            <Route path="/codePen/new" element={<ProtectedRoute children={<CodepenPage />} />} />
            <Route path="/codePen/:url" element={<ProtectedRoute children={<CodepenPage />} />} />
            <Route path="/kanban" element={<ProtectedRoute children={<KanbanListPage />} />} />
            <Route path="/chat" element={<ProtectedRoute children={<Chat />} />} />
            <Route path="/kanban/:projectId/:boardId" element={<ProtectedRoute children={<DashboardPage />} />} />
            <Route
              path="/kanban/:projectId/:boardId/settings"
              element={<ProtectedRoute children={<ProjectSettingsPage />} />}
            />
            <Route path="/MeetHome" element={<ProtectedRoute children={<Home />} />} />
            <Route path="/" element={<ProtectedRoute children={<MainScreenPage />} />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
