import { call, put, takeEvery } from "redux-saga/effects";
import ContactServices from "../../services/contacts";
import * as type from "../types";

function* getContacts(payload) {
  try {
    const { data } = yield call(ContactServices.getContacts, payload);
    yield put({ type: type.CONTACTS_SUCCESS, payload: data });
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      yield put({
        type: type.CONTACTS_FAILED,
        payload: { message: { message: error.response.data.error, status: error.response.status } },
      });
    } else {
      yield put({ type: type.CONTACTS_FAILED, payload: { message: error.response.data } });
    }
  }
}

function* incrementMessageBadge(payload) {
  yield put({ type: type.INCREMENT_MESSAGE_BADGE_SUCCESS, payload: { id: payload.payload.id } });
}

function* clearMessageBadge(payload) {
  yield put({ type: type.CLEAR_MESSAGE_BADGE_SUCCESS, payload: { id: payload.payload.id } });
}

function* userSaga() {
  yield takeEvery(type.CONTACTS_REQUESTED, getContacts);
  yield takeEvery(type.INCREMENT_MESSAGE_BADGE_REQUESTED, incrementMessageBadge);
  yield takeEvery(type.CLEAR_MESSAGE_BADGE_REQUESTED, clearMessageBadge);
}

export default userSaga;
