import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class NotificationsService{
    static async getNotifications(data){
        return await new ApiClient(HOST_NAMES.KANBAN).get(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/notification/${data.payload.notificationId}`);
    }

    static async createNotifications(data){
        return await new ApiClient(HOST_NAMES.KANBAN).post(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/notification`);
    } 
    static async updateNotifications(data){
        return await new ApiClient(HOST_NAMES.KANBAN).put(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/notification/${data.payload.notificationId}`, {data: data.payload.data});
    } 
}