import { combineReducers } from "redux";
import user from "./users";
import contacts from "./contacts";
import messages from "./chat/messages";
import codes from "./codes";
import currentCode from "./currentCode";
import project from "./kanban/project";
import board from "./kanban/board";
import column from "./kanban/column";
import card from "./kanban/card";
import label from "./kanban/label";
import task from "./kanban/task";
import comment from "./kanban/comment";
import roles from "./Roles/roles";
import notification from "./kanban/notification";
import history from "./kanban/history";
import {
  IBoardState,
  ICardState,
  ICommentState,
  IKanbanColumState,
  IKanbanProjectState,
  ITaskState,
} from "@kanban/domain";
import { INotificationState } from "@kanban/domain/Notification";
import { CodeState, CurrentCodeState } from "@codepen/domain/Code";
import { IKanbanLabelState } from "@kanban/domain/Label";
import { IUserState } from "@user/domain/User";
import { IRoleState } from "@/features/user/domain/Role";
import { IMessageState } from "@/features/chat/domain/interfaces/IMessageState";
import { IHistoryState } from "@/features/kanban/domain";
import chat from "./chat/channels";
import { IChatState } from "@/features/chat/domain/interfaces/IChatState";

export interface IRootState {
  user: IUserState;
  contacts: any;
  chat: IChatState;
  messages: IMessageState;
  codes: CodeState;
  currentCode: CurrentCodeState;
  project: IKanbanProjectState;
  board: IBoardState;
  column: IKanbanColumState;
  card: ICardState;
  label: IKanbanLabelState;
  roles: IRoleState;
  task: ITaskState;
  comment: ICommentState;
  notification: INotificationState;
  userSettings: any;
  history: IHistoryState;
}

const reducers = combineReducers({
  user,
  contacts,
  messages,
  chat,
  codes,
  currentCode,
  project,
  board,
  column,
  card,
  label,
  task,
  comment,
  roles,
  notification,
  history,
});

const rootReducer = (state: IRootState, action: any) => reducers(state, action);

export default rootReducer;
