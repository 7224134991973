import { ITask, ITaskState } from "../../../features/kanban/domain/Task";
import * as actions from "../../types";
import { StateStructure } from "../domain/StateStructure";

const initialState: ITaskState = {
  tasks: [],
  taskStatus: false,
  loading: false,
  error: "",
};

const incrementTasks = (taskState: ITask[], payload: any, type: string): ITask[] | null => {
  switch (type) {
    case actions.SET_TASKS_SUCCESS:
      payload.tasks.forEach((element: ITask) => {
        if (
          taskState.filter((item) => {
            return element._id === item._id ? item : null;
          }).length > 0
        ) {
          return;
        }
        taskState.push(element);
      });
      return taskState;
    case actions.CREATE_TASK_SUCCESS:
      taskState.push(payload);
      return taskState;
    default:
      return null;
  }
};

export default function task(state = initialState, { type, payload }: StateStructure): ITaskState {
  switch (type) {
    case actions.GET_TASKS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.GET_TASKS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        tasks: incrementTasks(state.tasks, payload, type),
      };
    case actions.SET_TASKS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.SET_TASKS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        tasks: incrementTasks(state.tasks, payload, type),
      };
    case actions.GET_TASKS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CREATE_TASK_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CREATE_TASK_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        tasks: incrementTasks(state.tasks, payload.newTask, type),
      };
    case actions.CREATE_TASK_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.UPDATE_TASK_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        taskStatus: false,
      };
    case actions.UPDATE_TASK_SUCCESS:
      const modifiedTasks = state.tasks.map((task) => {
        if (task._id === payload.taskUpdated._id) {
          return { ...payload.taskUpdated };
        }
        return task;
      });
      return {
        ...initialState,
        ...state,
        loading: false,
        tasks: modifiedTasks,
      };
    case actions.UPDATE_TASK_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_TASK_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        taskStatus: false,
      };
    case actions.DELETE_TASK_SUCCESS:
      const newTaskState = state.tasks.filter((task) => {
        return task._id !== payload.taskId ? task : null;
      });
      return {
        ...initialState,
        ...state,
        loading: false,
        tasks: newTaskState,
      };
    case actions.DELETE_TASK_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_ALL_TASKS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        taskStatus: false,
        tasks: [],
      };
    case actions.DELETE_ALL_TASKS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        taskStatus: payload.status,
      };
    case actions.DELETE_ALL_TASKS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CLEAR_TASK_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CLEAR_TASK_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        tasks: [],
        taskStatus: false,
      };
    default:
      return state;
  }
}
