import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { STRINGS } from "@constants/strings";
import { ReactElement } from "react";

export interface LoadingAnimationProps {
  text?: string;
  color?: string;
}

function LoadingAnimation({ text, color }: LoadingAnimationProps): ReactElement {
  return (
    <LoadingContainer>
      <Typography color={color ?? "white"} style={{ paddingBottom: "1em" }}>
        {text ?? STRINGS.LOADING_DATA}
      </Typography>
      <Stack sx={{ color: color ?? "white" }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
      </Stack>
    </LoadingContainer>
  );
}
const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default LoadingAnimation;
