import { call, put, takeEvery } from "redux-saga/effects";
import * as type from "../../types";
import {
  GetMessagesService,
  GetMessagesServiceResponse,
  SendMessageService,
  SendMessageServiceResponse,
} from "@/features/chat/services";
import { GetMessagesPayload, SendMessagePayload, SetNewMessagePayload } from "@store/sagas/domain/messagesSaga";

function* getMessages({ payload }: GetMessagesPayload) {
  try {
    const { data }: GetMessagesServiceResponse = yield call(GetMessagesService.handle, payload);

    yield put({ type: type.GET_MESSAGES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.GET_MESSAGES_FAILED,
      payload: { message: error.response.data },
    });
  }
}

function* sendMessages({ payload }: SendMessagePayload) {
  try {
    const { data }: SendMessageServiceResponse = yield call(SendMessageService.handle, payload);

    yield put({ type: type.SEND_MESSAGES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.SEND_MESSAGES_FAILED,
      payload: { message: error.response.data },
    });
  }
}

function* setNewMessage({ payload }: SetNewMessagePayload) {
  yield put({ type: type.SET_NEW_MESSAGE_SUCCESS, payload: { payload } });
}

function* clearMessagesStore(payload) {
  yield put({ type: type.CLEAR_MESSAGES_STORE_SUCCESS, payload });
}

function* changeCurrentChat(payload) {
  yield put({ type: type.CHANGE_CURRENT_CHAT_SUCCESS, payload });
}
function* backToContactsPage(payload) {
  yield put({ type: type.BACK_TO_CONTACTS_PAGE_SUCCESS, payload });
}
function* changeSearchChannelsInput(payload) {
  yield put({ type: type.CHANGE_CURRENT_CHAT_SUCCESS, payload });
}

function* messagesSaga() {
  yield takeEvery(type.GET_MESSAGES_REQUESTED, getMessages);
  yield takeEvery(type.SEND_MESSAGES_REQUESTED, sendMessages);
  yield takeEvery(type.SET_NEW_MESSAGE_REQUESTED, setNewMessage);
  yield takeEvery(type.CLEAR_MESSAGES_STORE_REQUESTED, clearMessagesStore);
  yield takeEvery(type.CHANGE_CURRENT_CHAT_REQUESTED, changeCurrentChat);
  yield takeEvery(type.BACK_TO_CONTACTS_PAGE_REQUESTED, backToContactsPage);
  yield takeEvery(type.CHANGE_SEARCH_CHANNELS_INPUT_REQUESTED, changeSearchChannelsInput);
}

export { messagesSaga };
