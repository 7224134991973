import { call, put, takeEvery } from "redux-saga/effects";
import RolesServices from "../../../services/Roles/roles";
import * as type from "../../types";

function* getRoles(payload){
    try{
        const {data} = yield call(RolesServices.getRoles, payload);
        yield put({type: type.GET_ROLES_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.GET_ROLES_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}

function* createRole(payload){
    try{
        const {data} = yield call(RolesServices.createRole, payload);
        yield put({type: type.CREATE_ROLE_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.CREATE_ROLE_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}


function* deleteRole(payload){
    try{
        const {data} = yield call(RolesServices.deleteRole, payload);
        yield put({type: type.DELETE_ROLE_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.DELETE_ROLE_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}

function* rolesSaga(){   
    yield takeEvery(type.GET_ROLES_REQUESTED, getRoles); 
    yield takeEvery(type.CREATE_ROLE_REQUESTED, createRole); 
    yield takeEvery(type.DELETE_ROLE_REQUESTED, deleteRole); 
}

export default rolesSaga;