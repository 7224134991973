import { PersonRounded } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { UserInSession } from "@api/getSession";
import LoadingAnimation from "@/features/loadingAnimation";

import { IRootState } from "@store/reducers/rootReducer";
import UserAvatar from "../UserAvatar/UserAvatar";

function CurrentUserAvatar(): ReactElement {
  const { loading, user } = useSelector((state: IRootState) => state.user);
  const hasAvatarUrl = user.avatarUrl || UserInSession.avatarUrl;
  const hasAvatarBuffer = user.avatar || UserInSession.avatar;
  return (
    <>
      {UserInSession?._id && (hasAvatarBuffer || hasAvatarUrl) ? (
        loading ? (
          <LoadingAnimation />
        ) : (
          <UserAvatar
            className="avatar"
            avatar_url={UserInSession.avatarUrl ?? user.avatarUrl}
            avatar={UserInSession.avatar ?? user.avatar}
          />
        )
      ) : (
        <div
          className="avatar"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            background: "black",
          }}
        >
          <PersonRounded fontSize="large" />
        </div>
      )}
    </>
  );
}

export default CurrentUserAvatar;
