import {
  IGroup,
  subscribedGroups,
} from "@/features/chat/domain/interfaces/IGroup";
import { IUser } from "@user/domain/User";

const isJson = (json: string): boolean => {
  try {
    JSON.parse(json);
    return true;
  } catch (err: any) {
    return false;
  }
};

export const UserInSession: IUser = JSON.parse(localStorage.getItem("user"));
export const UserInSessionF = (): IUser =>
  JSON.parse(localStorage.getItem("user"));
export const SubscribedGroups: IGroup[] = isJson(
  localStorage.getItem(subscribedGroups)
)
  ? JSON.parse(localStorage.getItem(subscribedGroups))
  : [];
