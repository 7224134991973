import { IRole, IRoleState } from "../../../features/user/domain/Role";
import * as actions from "../../types";
import { StateStructure } from "../domain/StateStructure";

const initialState: IRoleState = {
  roles: [],
  loading: false,
  error: "",
};

const handleRolesActions = (rolesState: IRole[], payload: any, action: string) => {
  switch (action) {
    case actions.CREATE_ROLE_SUCCESS:
      rolesState.push(payload.role);
      return rolesState;
    case actions.DELETE_ROLE_REQUESTED:
      const newState = rolesState.filter((role) => role._id !== payload.roleId);
      return newState;
    default:
      return null;
  }
};

export default function roles(state = initialState, { type, payload }: StateStructure): IRoleState {
  switch (type) {
    case actions.GET_ROLES_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        roles: [],
      };
    case actions.GET_ROLES_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        roles: payload,
      };
    case actions.GET_ROLES_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CREATE_ROLE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CREATE_ROLE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        roles: handleRolesActions(state.roles, payload, type),
      };
    case actions.CREATE_ROLE_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_ROLE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        roles: handleRolesActions(state.roles, payload, type),
      };
    case actions.DELETE_ROLE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.DELETE_ROLE_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    default:
      return state;
  }
}
