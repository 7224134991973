import { IBoard, IBoardState } from "../../../features/kanban/domain/Board";
import * as actions from "../../types";
import { StateStructure } from "../domain/StateStructure";

const initialState: IBoardState = {
  currentBoard: undefined,
  loading: true,
  error: "",
};

function handleCloseCard(board: IBoard, payload: any): IBoard {
  const { cardId, title } = payload;
  if (!board.closedCards) {
    board.closedCards = [{ _id: cardId, title }];
  }
  board.closedCards.push({ _id: cardId, title });
  return board;
}

export default function board(
  state = initialState,
  { type, payload }: StateStructure
): IBoardState {
  switch (type) {
    case actions.GET_BOARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentBoard: undefined,
      };
    case actions.GET_BOARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentBoard: payload.findBoard,
      };
    case actions.GET_BOARD_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.SET_BOARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentBoard: undefined,
      };
    case actions.SET_BOARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentBoard: payload.board,
      };
    case actions.CREATE_BOARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CREATE_BOARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentBoard: payload.newBoard,
      };
    case actions.CREATE_BOARD_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CLEAR_BOARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentBoard: undefined,
      };
    case actions.CLEAR_BOARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.CLOSE_CARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentBoard: handleCloseCard(state.currentBoard, payload),
      };
    case actions.CLOSE_CARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.CLOSE_CARD_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    default:
      return state;
  }
}
