import { ReactElement } from "react";
import "./App.css";
import { AppRoutes } from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App(): ReactElement<any, any> {
  return (
    <div className="App">
      <AppRoutes />
      <ToastContainer style={{ zIndex: `99999` }} />
    </div>
  );
}

export default App;
