import { ToastOptions, ToastPosition, toast } from "react-toastify";
interface ToastPositions {
  BOTTOM_LEFT: ToastPosition;
  BOTTOM_CENTER: ToastPosition;
  BOTTOM_RIGHT: ToastPosition;
  TOP_LEFT: ToastPosition;
  TOP_CENTER: ToastPosition;
  TOP_RIGHT: ToastPosition;
}
export const TOAST_POSITIONS: ToastPositions = {
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_CENTER: "bottom-center",
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  TOP_RIGHT: "top-right",
};

export const toastOptions: ToastOptions = {
  position: "bottom-right",
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};

class ToastHandler {
  toastOptions: ToastOptions = {
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  static success(message: string, position?: ToastPosition) {
    toastOptions.position = position ?? TOAST_POSITIONS.BOTTOM_RIGHT;
    toast.success(message, toastOptions);
  }
  static error = (message: string, position?: ToastPosition) => {
    toastOptions.position = position ?? TOAST_POSITIONS.BOTTOM_RIGHT;

    toast.error(message, toastOptions);
  };
  static warn = (message: string, position?: ToastPosition) => {
    toastOptions.position = position ?? TOAST_POSITIONS.BOTTOM_RIGHT;
    toast.warn(message, toastOptions);
  };
  static info = (message: string, position?: ToastPosition) => {
    toastOptions.position = position ?? TOAST_POSITIONS.BOTTOM_RIGHT;
    toast.info(message, toastOptions);
  };
}
export { ToastHandler };
