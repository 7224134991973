import { call, put, takeEvery } from "redux-saga/effects";
import NotificationsService from "../../../services/kanban/notification";
import * as type from "../../types";

function* getNotifications(payload){
    try{
        const {data} = yield call(NotificationsService.getNotifications, payload);
        yield put({type: type.GET_PROJECT_NOTIFICATIONS_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.GET_PROJECT_NOTIFICATIONS_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}

function* createNotifications(payload){
    try{
        const {data} = yield call(NotificationsService.createNotifications, payload);
        yield put({type: type.CREATE_PROJECT_NOTIFICATIONS_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.CREATE_PROJECT_NOTIFICATIONS_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}


function* updateNotifications(payload){
    try{
        const {data} = yield call(NotificationsService.updateNotifications, payload);
        yield put({type: type.UPDATE_PROJECT_NOTIFICATIONS_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.UPDATE_PROJECT_NOTIFICATIONS_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}

function* notificationsSaga(){   
    yield takeEvery(type.GET_PROJECT_NOTIFICATIONS_REQUESTED, getNotifications); 
    yield takeEvery(type.CREATE_PROJECT_NOTIFICATIONS_REQUESTED, createNotifications); 
    yield takeEvery(type.UPDATE_PROJECT_NOTIFICATIONS_REQUESTED, updateNotifications); 
}

export default notificationsSaga;