import { useEffect } from "react";
import { isExpired as isTokenExpired } from "react-jwt";
import { useDispatch } from "react-redux";

import { retrieveUserSession } from "@/store/actions/users";

import { UserInSession } from "@/api/getSession";
import { IUser } from "@/features/user/domain/User";

export const verifyIfUserIsAuthenticated = (user: IUser): boolean => {
  if (!user?._id || !user.accessToken) {
    return false;
  }
  const isExpired = isTokenExpired(user.accessToken);
  if (isExpired) {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
  }

  return !isExpired;
};

export const verifyIfTokenIsExpired = (): boolean => {
  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = localStorage.getItem("accessToken");
  if (user && user.accessToken && !user?._id) {
    return false;
  }

  const isExpired = isTokenExpired(user?.accessToken ?? accessToken);
  if (isExpired) {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
  }
  return !isExpired;
};

export const useIsAuthenticated = (): boolean => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveUserSession({ user: UserInSession }));
  }, [dispatch]);

  return verifyIfUserIsAuthenticated(UserInSession);
};
