import { call, put, takeEvery } from "redux-saga/effects";

import * as type from "../types";

import {
  GetCodesService,
  GetCodeByUrlService,
  SharePublicCodeService,
  CreateCodeService,
  UpdateCodeService,
  GetCodesServiceResponse,
  GetCodeByUrlServiceResponse,
  UpdateCodeServiceResponse,
} from "@/features/codepen/services";

import { GetCodeByUrlPayload } from "./domain/codesSaga/getCodeByUrlPayload";
import { GetCodePayload } from "./domain/codesSaga/getCodePayload";
import { UpdateCodePayload } from "./domain/codesSaga/updateCodePayload";

function* getCodes({ payload }: GetCodePayload) {
  try {
    const { data }: GetCodesServiceResponse = yield call(GetCodesService.handle, payload);

    yield put({ type: type.GET_CODES_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.GET_CODES_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* getCodeByUrl({ payload }: GetCodeByUrlPayload) {
  try {
    const { data }: GetCodeByUrlServiceResponse = yield call(
      GetCodeByUrlService.handle,
      payload.url
    );

    yield put({ type: type.GET_CODE_BY_URL_SUCCESS, payload: data.code });
  } catch (error) {
    yield put({
      type: type.GET_CODE_BY_URL_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* updateCode({ payload }: UpdateCodePayload) {
  try {
    const { data }: UpdateCodeServiceResponse = yield call(UpdateCodeService.handle, payload);
    yield put({ type: type.UPDATE_CODE_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.UPDATE_CODE_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}
function* sharePublicCode(payload) {
  try {
    const { data } = yield call(SharePublicCodeService.handle, payload);
    yield put({ type: type.SHARE_PUBLIC_CODE_SUCCESS, payload: data.publicUrl });
  } catch (error) {
    yield put({
      type: type.SHARE_PUBLIC_CODE_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* createCode(payload) {
  try {
    const { data } = yield call(CreateCodeService.handle, payload.payload);
    yield put({ type: type.CREATE_CODE_SUCCESS, payload: data });
  } catch (error: any) {
    yield put({
      type: type.CREATE_CODE_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* clearCurrentCode() {
  yield put({ type: type.CLEAR_CURRENT_CODE_SUCCESS, payload: {} });
}

function* userSaga() {
  yield takeEvery(type.GET_CODES_REQUESTED, getCodes);
  yield takeEvery(type.GET_CODE_BY_URL_REQUESTED, getCodeByUrl);
  yield takeEvery(type.SHARE_PUBLIC_CODE_REQUESTED, sharePublicCode);
  yield takeEvery(type.UPDATE_CODE_REQUESTED, updateCode);
  yield takeEvery(type.CREATE_CODE_REQUESTED, createCode);
  yield takeEvery(type.CLEAR_CURRENT_CODE_REQUESTED, clearCurrentCode);
}

export default userSaga;
