import { IKanbanLabelState, ILabel } from "../../../features/kanban/domain/Label";
import * as actions from "../../types";
import { StateStructure } from "../domain/StateStructure";

const initialState: IKanbanLabelState = {
  labels: [],
  labelDeleteStatus: false,
  loading: false,
  error: "",
};

const incrementLabels = (labelsState: ILabel[], payload: any, type: string): ILabel[] => {
  switch (type) {
    case actions.SET_LABELS_SUCCESS:
      payload.labels.forEach((element: ILabel) => {
        if (labelsState.filter((item) => element._id === item._id).length > 0) {
          return;
        }
        labelsState.push(element);
      });
      return labelsState;
    case actions.CREATE_LABEL_SUCCESS:
      labelsState.push(payload);
      return labelsState;
    default:
      return null;
  }
};

export default function label(state = initialState, { type, payload }: StateStructure) {
  switch (type) {
    case actions.GET_LABELS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.GET_LABELS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        labels: incrementLabels(state.labels, payload, type),
      };
    case actions.GET_LABELS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.SET_LABELS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.SET_LABELS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        labels: incrementLabels(state.labels, payload, type),
      };
    case actions.CREATE_LABEL_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CREATE_LABEL_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        labels: incrementLabels(state.labels, payload.newLabel, type),
      };
    case actions.CREATE_LABEL_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_LABEL_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        labelDeleteStatus: false,
      };
    case actions.DELETE_LABEL_SUCCESS:
      const newLabelsState = state.labels.filter((label) => label._id !== payload.labelId);
      return {
        ...initialState,
        ...state,
        loading: false,
        labels: newLabelsState,
      };
    case actions.DELETE_LABEL_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_ALL_LABELS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        labelDeleteStatus: false,
        labels: [],
      };
    case actions.DELETE_ALL_LABELS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        labelDeleteStatus: payload.status,
      };
    case actions.DELETE_ALL_LABELS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CLEAR_LABEL_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CLEAR_LABEL_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        labels: [],
        labelDeleteStatus: false,
      };
    default:
      return state;
  }
}
