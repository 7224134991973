import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class CardServices{
    static async getCard(data){
        return await new ApiClient(HOST_NAMES.KANBAN)
            .get(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}`);
    }

    static async updateCard(data){
        return await new ApiClient(HOST_NAMES.KANBAN)
            .put(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}`, data.payload);
    }

    static async createCard(data){
        return await new ApiClient(HOST_NAMES.KANBAN)
        .post(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards`,data.payload)
    }

    static async deleteCard(data){
        return await new ApiClient(HOST_NAMES.KANBAN)
        .delete(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}`);
    }

}