import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class BoardServices {
  static async getBoard(data) {
    return await new ApiClient(HOST_NAMES.KANBAN).get(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}`
    );
  }

  static async createBoard(data) {
    return await new ApiClient(HOST_NAMES.KANBAN).post(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards`,
      { title: data.payload.title }
    );
  }

  static async closeCard(data) {
    return await new ApiClient(HOST_NAMES.KANBAN).put(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}`,
      { closeCard: true }
    );
  }
}
