import { call, put, takeEvery } from "redux-saga/effects";
import CommentServices from "../../../services/kanban/comment";
import * as type from "../../types";

function* getComments(payload){
    try{
        const {data} = yield call(CommentServices.getComments, payload);
        yield put({type: type.GET_COMMENTS_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.GET_COMMENTS_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}

function* createComment(payload){
    try{
        const {data} = yield call(CommentServices.createComment, payload);
        yield put({type: type.CREATE_COMMENT_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.CREATE_COMMENT_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}

function* updateComment(payload){
    try{
        const {data} = yield call(CommentServices.updateComment, payload);
        yield put({type: type.UPDATE_COMMENT_SUCCESS, payload: data})
    } catch(error){
        yield put({
            type: type.UPDATE_COMMENT_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}

function* deleteComment(payload){
    try{
        const {data} = yield call(CommentServices.deleteComment, payload);
        yield put({type: type.DELETE_COMMENT_SUCCESS, payload: {data, commentId: payload.payload.commentId}})
    } catch(error){
        yield put({
            type: type.DELETE_COMMENT_FAILED,
            payload: {message: error?.response?.data ?? error.message},
        });
    }   
}

function* commentSaga(){
    yield takeEvery(type.GET_COMMENTS_REQUESTED, getComments);
    yield takeEvery(type.CREATE_COMMENT_REQUESTED, createComment);
    yield takeEvery(type.UPDATE_COMMENT_REQUESTED, updateComment);
    yield takeEvery(type.DELETE_COMMENT_REQUESTED, deleteComment);
}

export default commentSaga;