import { ICommentState } from "../../../features/kanban/domain/Comment";
import * as actions from "../../types";
import { StateStructure } from "../domain/StateStructure";

const initialState: ICommentState = {
  comments: [],
  loading: false,
  error: "",
};

export default function comment(
  state = initialState,
  { type, payload }: StateStructure
): ICommentState {
  switch (type) {
    case actions.GET_COMMENTS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        comments: [],
      };
    case actions.GET_COMMENTS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        comments: payload.comments,
      };
    case actions.GET_COMMENTS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CREATE_COMMENT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CREATE_COMMENT_SUCCESS:
      let newState = state.comments;
      let comment = Object.assign({}, payload.newComment);
      comment.card = payload.newComment.card._id;
      comment.author = payload.newComment.author._id;
      newState.unshift(comment);
      return {
        ...initialState,
        ...state,
        loading: false,
        comments: newState,
      };
    case actions.CREATE_COMMENT_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.UPDATE_COMMENT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.UPDATE_COMMENT_SUCCESS:
      const modifiedComments = state.comments.map((comment) => {
        if (comment._id === payload.commentUpdated._id) {
          return { ...payload.commentUpdated };
        }
        return comment;
      });
      return {
        ...initialState,
        ...state,
        loading: false,
        comments: modifiedComments,
      };
    case actions.UPDATE_COMMENT_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_COMMENT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.DELETE_COMMENT_SUCCESS:
      const newCommentState = state.comments.filter((comment) => comment._id !== payload.commentId);
      return {
        ...initialState,
        ...state,
        loading: false,
        comments: newCommentState,
      };
    case actions.DELETE_COMMENT_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    default:
      return state;
  }
}
