import {
  VideoCall,
  Mail as MailIcon,
  Inbox as InboxIcon,
  Home as HomeIcon,
} from "@mui/icons-material";
import { ReactElement } from "react";
import { Users } from "react-feather";

interface RoutesPathsType {
  name?: string;
  path: string;
  icon?: ReactElement;
  shoulBeShownOnMenu: boolean;
  shouldShowAppBar: boolean;
}

export const RoutesPaths: RoutesPathsType[] = [
  {
    path: "",
    shoulBeShownOnMenu: false,
    shouldShowAppBar: true,
  },
  {
    path: "/setup",
    shoulBeShownOnMenu: false,
    shouldShowAppBar: false,
  },
  {
    path: "recover",
    shoulBeShownOnMenu: false,
    shouldShowAppBar: false,
  },
  {
    path: "/login",
    shoulBeShownOnMenu: false,
    shouldShowAppBar: false,
  },
  {
    name: "Home",
    path: "/home",
    icon: <HomeIcon />,
    shoulBeShownOnMenu: true,
    shouldShowAppBar: true,
  },
  {
    name: "Code Pen",
    path: "/codePen",
    icon: <InboxIcon />,
    shoulBeShownOnMenu: true,
    shouldShowAppBar: true,
  },
  {
    name: "Chat",
    path: "/chat",
    icon: <MailIcon />,
    shoulBeShownOnMenu: true,
    shouldShowAppBar: true,
  },
  {
    name: "Kanban",
    path: "/kanban",
    icon: <InboxIcon />,
    shoulBeShownOnMenu: true,
    shouldShowAppBar: true,
  },
  {
    name: "Meet",
    path: "/MeetHome",
    icon: <VideoCall />,
    shoulBeShownOnMenu: true,
    shouldShowAppBar: true,
  },
  {
    path: "/room",
    shoulBeShownOnMenu: false,
    shouldShowAppBar: false,
  },
  {
    name: "Profile",
    path: "/profile",
    icon: <Users />,
    shoulBeShownOnMenu: true,
    shouldShowAppBar: true,
  },
  // {
  //   name: "Chat3",
  //   path: "/old-chat",
  //   icon: <MailIcon />,
  //   shoulBeShownOnMenu: true,
  //   shouldShowAppBar: true,
  // },
  // {
  //   name: "Chat",
  //   path: "/pubnub-chat",
  //   icon: <MailIcon />,
  //   shoulBeShownOnMenu: true,
  //   shouldShowAppBar: true,
  // },
];
