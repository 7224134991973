import ApiClient from "./ApiClient";
import { API_ROUTES, HOST_NAMES } from "../constants";

export default class UsersService {
  static async login(data) {
    return await new ApiClient(HOST_NAMES.USER).postData(API_ROUTES.USER.LOGIN, data.payload);
  }

  static async register(data) {
    return await new ApiClient(HOST_NAMES.USER).postData(API_ROUTES.USER.REGISTER, data.payload);
  }

  static async logout(data) {
    return await new ApiClient(HOST_NAMES.CHAT).postData(
      `${API_ROUTES.CHAT.LOGOUT}/${data.payload.id}`,
      data.payload
    );
  }

  static async setAvatar(data) {
    return await new ApiClient(HOST_NAMES.USER).postData(
      `${API_ROUTES.USER.SET_AVATAR}/${data.payload.id}`,
      data.payload.image
    );
  }

  static async updateUser(data) {
    const id = data.payload.id;
    delete data.payload.id;

    return await new ApiClient(HOST_NAMES.USER).put(
      `${API_ROUTES.USER.MAIN_ROUTE}/${id}`,
      data.payload
    );
  }

  static async recoverPassword(data) {
    delete data.payload.id;

    return await new ApiClient(HOST_NAMES.USER).postData(
      `${API_ROUTES.USER.RECOVER_PASSWORD}`,
      data.payload
    );
  }
}
