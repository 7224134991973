import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class CommentServices{

    static async getComments(data){
        return await new ApiClient(HOST_NAMES.KANBAN)
            .get(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/comments`);
    }

    static async createComment(data){
        return await new ApiClient(HOST_NAMES.KANBAN)
            .post(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/comments`,{text: data.payload.text});
    }

    static async updateComment(data){
        return await new ApiClient(HOST_NAMES.KANBAN)
            .put(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/comments/${data.payload.commentId}`,{text: data.payload.text});
    }

    static async deleteComment(data){
        return await new ApiClient(HOST_NAMES.KANBAN)
            .delete(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/comments/${data.payload.commentId}`);
    }
}