import { API_ROUTES, HOST_NAMES } from "@/constants";
import ApiClient from "@/services/ApiClient";
import { AxiosResponse } from "axios";
import { MessageModel } from "@chat/domain/models/MessageModel";
import { MessageTypeEnum } from "../domain/enums/MessageTypeEnum";

export interface GetMessagesServiceParams {
  channels: string[];
  type?: MessageTypeEnum;
  page: number;
  limit?: number;
}

export interface GetMessagesServiceResponse {
  data: MessageModel[];
}

export class GetMessagesService {
  static async handle({
    channels,
    type,
    page,
    limit,
  }: GetMessagesServiceParams): Promise<AxiosResponse<GetMessagesServiceResponse, any>> {
    const result = await new ApiClient(HOST_NAMES.CHAT).get(API_ROUTES.CHAT.MESSAGES, {
      params: { channels, type, page, limit },
    });

    result.data.map((message) => {
      if (typeof message.createdAt === "string") {
        message.createdAt = new Date(Date.parse(message.createdAt));
      }
      if (typeof message.updatedAt === "string") {
        message.updatedAt = new Date(Date.parse(message.updatedAt));
      }
      return message;
    });

    return result;
  }
}
