import ApiClient from "@services/ApiClient";
import { API_ROUTES, HOST_NAMES } from "@/constants";
import { AxiosResponse } from "axios";

export class SharePublicCodeService {
  static async handle({ payload }): Promise<AxiosResponse<any, any>> {
    return await new ApiClient(HOST_NAMES.CODEPEN).post(
      `${API_ROUTES.CODEPEN.GENERATE_PUBLIC_URL}/${payload.projectUrl}`
    );
  }
}
