import { API_ROUTES, HOST_NAMES } from "@/constants";
import ApiClient from "@/services/ApiClient";
import { AxiosResponse } from "axios";
import { IAttach } from "../domain/interfaces/IAttach";
import { MessageModel } from "../domain/models/MessageModel";

export interface SendMessageServiceParams {
  content: string;
  channels: string[];
  type: boolean;
  attachments: IAttach[];
}

export interface SendMessageServiceResponse {
  data: MessageModel;
}

export class SendMessageService {
  static async handle({
    content,
    channels,
    type,
  }: SendMessageServiceParams): Promise<AxiosResponse<SendMessageServiceResponse, any>> {
    return await new ApiClient(HOST_NAMES.CHAT).post(API_ROUTES.CHAT.MESSAGES, {
      content,
      channels,
      type,
    });
  }
}
