import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";

import Container from "@mui/material/Container";

import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { UserInSession } from "@api/getSession";

import CurrentUserAvatar from "@user/components/CurrentUserAvatar/CurrentUserAvatar";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { userLogout } from "@store/actions/users";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { RoutesPaths } from "@constants/constants";
import { useIsAuthenticated } from "@hooks/useIsAuthenticated";
import { IRootState } from "@store/reducers/rootReducer";
import { HamburgerMenu } from "@hamburgerMenu";
import { HTMLElementEvent } from "@/features/kanban/pages/Board/components/card/cardInfo/descriptionInput/interfaces/DescriptionInput";

export default function NavBar(): React.ReactElement {
  const dispatch = useDispatch();

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const showMenu = useIsAuthenticated();
  const [shouldShowMenuInCurrentPage, setShouldShowMenuInCurrentPage] = React.useState(false);

  const cookies = new Cookies();

  const { user: currentUser } = useSelector((state: IRootState) => state.user);

  useEffect(() => {
    const routeSplitter = window.location.href.split("/");
    const route = RoutesPaths.filter(
      (route) =>
        route.path.includes(routeSplitter[3]) ||
        route.path === routeSplitter[3] ||
        route.path.includes(routeSplitter[3].split("?")[0])
    )[0];
    setShouldShowMenuInCurrentPage(route.shouldShowAppBar);
  }, []);

  const handleLogout = (e: HTMLElementEvent<HTMLDivElement>): void => {
    e.preventDefault();

    dispatch(
      userLogout({
        id: cookies.get("userId") ?? UserInSession._id,
      })
    );
    cookies.remove("userId");
    localStorage.removeItem("user");
    window.location.assign("/login");
  };

  const settings = [
    {
      text: "Profile",
      action: (e) => {
        handleCloseUserMenu();
      },
    },

    {
      text: "Dashboard",
      action: () => window.open("https://admin.js-workspace.com"),
    },
    {
      text: "Logout",
      action: (e: HTMLElementEvent<HTMLDivElement>) => {
        handleCloseUserMenu();
        handleLogout(e);
      },
    },
  ];

  const handleOpenUserMenu = (event: any): void => {
    setAnchorElUser(event.currentTarget);
    setOpen(true);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
    setOpen(false);
  };

  return (
    <>
      {cookies.get("userId") !== null && showMenu && UserInSession?._id && (
        <AppBar position="static" style={{ background: "#1c1f2e", borderBottom: "2px solid rgba(36 39 54)" }}>
          <Container style={{ maxWidth: "none" }}>
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1 }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  {shouldShowMenuInCurrentPage ? <HamburgerMenu /> : null}
                </IconButton>
              </Box>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "flex", lg: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {UserInSession ? `${UserInSession.company.name ?? "JS"} Workspace ` : "JS Workspace"}
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <CurrentUserAvatar />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  anchorEl={anchorElUser}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <div key={`${setting.text}`}>
                      <MenuItem onClick={setting.action}>
                        {setting.text === "Profile" ? (
                          <Link to="/profile">
                            <Typography textAlign="center">{setting.text}</Typography>
                          </Link>
                        ) : (
                          ((currentUser.userType === "admin" && setting.text === "Dashboard") ||
                            setting.text !== "Dashboard") && <Typography textAlign="center">{setting.text}</Typography>
                        )}
                      </MenuItem>
                    </div>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </>
  );
}
