import { IUserState } from "../../features/user/domain/User";
import * as actions from "../types";
import { StateStructure } from "./domain/StateStructure";

const initialState: IUserState = {
  user: {},
  loading: false,
  error: {},
  recoverPasswordSent: false,
  loadingRecoverPassword: false,
};

export default function users(state = initialState, { type, payload }: StateStructure) {
  switch (type) {
    case actions.USER_LOGIN_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        user: payload,
      };
    case actions.USER_LOGIN_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        user: payload,
      };
    case actions.USER_LOGIN_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.USER_REGISTER_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        user: payload,
      };
    case actions.USER_REGISTER_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        user: payload,
      };
    case actions.USER_REGISTER_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.error,
      };
    case actions.USER_LOGOUT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        user: payload,
      };
    case actions.USER_LOGOUT_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        user: null,
      };
    case actions.USER_LOGOUT_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.USER_RETRIEVE_SESSION_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.USER_RETRIEVE_SESSION_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        user: payload,
      };

    case actions.USER_SET_AVATAR_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        payload: payload.data,
      };
    case actions.USER_SET_AVATAR_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        user: { image: payload },
      };
    case actions.USER_SET_AVATAR_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.USER_UPDATE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        payload: payload.data,
      };
    case actions.USER_UPDATE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        user: payload,
        error: {},
      };
    case actions.USER_UPDATE_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.USER_RECOVER_PASSWORD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loadingRecoverPassword: true,
        payload: payload.data,
      };
    case actions.USER_RECOVER_PASSWORD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loadingRecoverPassword: false,
        recoverPasswordSent: payload,
        error: {},
      };
    case actions.USER_RECOVER_PASSWORD_FAILED:
      return {
        ...initialState,
        ...state,
        loadingRecoverPassword: false,
        error: payload.message,
      };

    default:
      return state;
  }
}
