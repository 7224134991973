import { CurrentCodeState } from "../../features/codepen/domain/Code";
import * as actions from "../types";
import { StateStructure } from "./domain/StateStructure";

const initialState: CurrentCodeState = {
  _id: "",
  html: "",
  css: "",
  js: "",
  url: "",
  publicUrl: "",
  name: "",
  createdBy: "",
  loading: false,
  error: { message: "", code: null },
  message: "",
};

export default function currentCode(
  state = initialState,
  { type, payload }: StateStructure
): CurrentCodeState {
  switch (type) {
    case actions.GET_CODE_BY_URL_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.GET_CODE_BY_URL_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        ...payload,
        searched: true,
      };
    case actions.GET_CODE_BY_URL_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.UPDATE_CODE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.UPDATE_CODE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        ...payload.code,
        searched: true,
        message: "Sucesso ao atualizar código!",
      };
    case actions.UPDATE_CODE_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.CREATE_CODE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CREATE_CODE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        ...payload.code,
        searched: true,
        message: "Sucesso ao criar código!",
      };
    case actions.CREATE_CODE_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.SHARE_PUBLIC_CODE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };

    case actions.SHARE_PUBLIC_CODE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        publicUrl: payload,
      };

    case actions.SHARE_PUBLIC_CODE_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.CLEAR_CURRENT_CODE_SUCCESS:
      return {
        ...initialState,
      };
    case actions.CLEAR_CURRENT_CODE_REQUESTED:
      return {
        ...initialState,
        ...state,
      };

    default:
      return state;
  }
}
