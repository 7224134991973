import { INotificationState } from "../../../features/kanban/domain/Notification";
import * as actions from "../../types";
import { StateStructure } from "../domain/StateStructure";

const initialState: INotificationState = {
  currentNotifications: undefined,
  loading: false,
  error: "",
};

export default function notification(
  state = initialState,
  { type, payload }: StateStructure
): INotificationState {
  switch (type) {
    case actions.GET_PROJECT_NOTIFICATIONS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentNotifications: undefined,
      };
    case actions.GET_PROJECT_NOTIFICATIONS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentNotifications: payload.notifications,
      };
    case actions.GET_PROJECT_NOTIFICATIONS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CREATE_PROJECT_NOTIFICATIONS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentNotifications: undefined,
      };
    case actions.CREATE_PROJECT_NOTIFICATIONS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentNotifications: payload.newNotifications,
      };
    case actions.CREATE_PROJECT_NOTIFICATIONS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.UPDATE_PROJECT_NOTIFICATIONS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.UPDATE_PROJECT_NOTIFICATIONS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentNotifications: payload.notification,
      };
    case actions.UPDATE_PROJECT_NOTIFICATIONS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    default:
      return state;
  }
}
