import { IChatState } from "@/features/chat/domain/interfaces/IChatState";
import * as actions from "../../types";
import { StateStructure } from "../domain/StateStructure";
import { ActionsMapStructure } from "../domain/ActionsMapStructure";
import { ChannelModel } from "@/features/chat/domain/models/ChannelModel";

const initialState: IChatState = {
  searchInput: "",
  groups: [],
  users: [],
  loading: false,
  error: {},
};

const actionsMap: ActionsMapStructure<IChatState> = {
  [actions.CHANGE_SEARCH_CHANNELS_INPUT_REQUESTED]: (state, _) => {
    return {
      ...initialState,
      ...state,
    };
  },
  [actions.CHANGE_SEARCH_CHANNELS_INPUT_SUCCESS]: (state, payload) => {
    return {
      ...initialState,
      ...state,
      searchInput: payload.searchInput,
    };
  },
  [actions.GET_CHAT_CHANNELS_REQUESTED]: (state, _) => {
    return { ...initialState, ...state, loading: true };
  },
  [actions.GET_CHAT_CHANNELS_SUCCESS]: (state, payload) => {
    const result = { ...initialState, ...state, loading: false, ...payload };
    return result;
  },
  [actions.GET_CHAT_CHANNELS_FAILED]: (state, payload) => {
    return {
      ...initialState,
      ...state,
      loading: false,
      error: payload.message,
    };
  },
  [actions.CREATE_CHAT_GROUP_REQUESTED]: (state, _) => {
    return { ...initialState, ...state, loading: true };
  },
  [actions.CREATE_CHAT_GROUP_SUCCESS]: (state, payload) => {
    const newGroups = state.groups;
    const newGroup: ChannelModel = {
      id: payload._id,
      name: payload.name,
      avatarUrl: payload.avatarUrl,
    };
    newGroups.push(newGroup);

    const result = {
      ...initialState,
      ...state,
      loading: false,
      groups: newGroups,
    };
    return result;
  },
  [actions.CREATE_CHAT_GROUP_FAILED]: (state, payload) => {
    return {
      ...initialState,
      ...state,
      loading: false,
      error: payload.message,
    };
  },
};

const chat = (state = initialState, { type, payload }: StateStructure) => {
  if (actionsMap[type]) {
    return actionsMap[type](state, payload);
  }
  return state;
};

export default chat;
