import { CodeState } from "../../features/codepen/domain/Code";
import * as actions from "../types";
import { StateStructure } from "./domain/StateStructure";

const initialState: CodeState = {
  codes: null,
  pageNumber: 1,
  name: "",
  totalDocs: 1,
  totalPages: 1,
  currentCode: null,
  loading: false,
  error: "",
  searched: false,
};

export default function codes(state = initialState, { type, payload }: StateStructure): CodeState {
  switch (type) {
    case actions.GET_CODES_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        codes: payload,
      };
    case actions.GET_CODES_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        codes: payload.docs,
        totalDocs: payload.totalDocs,
        totalPages: payload.totalPages,
        searched: true,
      };
    case actions.GET_CODES_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message.message,
      };
    default:
      return state;
  }
}
