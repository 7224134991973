import { ICard, ICardState } from "../../../features/kanban/domain/Card";
import * as actions from "../../types";

const initialState: ICardState = {
  allCards: [],
  cardStatus: false,
  loading: false,
  error: "",
};

const incrementCards = (cardsState: any[], payload: any, type: string): ICard[] => {
  switch (type) {
    case actions.SET_CARDS_SUCCESS:
      payload.cards.forEach((card: ICard) => {
        if (cardsState.filter((item) => card._id === item._id).length > 0) {
          return;
        }
        cardsState.push(card);
      });
      return cardsState;
    default:
      return null;
  }
};

export default function card(
  state = initialState,
  { type, payload }: { type: string; payload: any }
): ICardState {
  switch (type) {
    case actions.SET_CARDS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.SET_CARDS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        allCards: incrementCards(state.allCards, payload, type),
      };
    case actions.DELETE_ALL_CARDS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        cardStatus: false,
        allCards: [],
      };
    case actions.DELETE_ALL_CARDS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        cardStatus: payload.status,
      };
    case actions.DELETE_ALL_CARDS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CLEAR_CARDS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CLEAR_CARDS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        allCards: [],
        cardStatus: false,
      };

    default:
      return state;
  }
}
