import { IKanbanColumn, IKanbanColumState } from "../../../features/kanban/domain";
import * as actions from "../../types";

const initialState: IKanbanColumState = {
  columns: [],
  columnState: false,
  currentCard: undefined,
  loading: false,
  loadingCard: false,
  error: "",
};

const incrementColumns = (columnsState: any[], payload: any): IKanbanColumn[] => {
  columnsState.push(payload);
  return columnsState;
};

const cardActions = (payload: any, type: string): IKanbanColumn[] => {
  let newColumnState = payload.columns;
  let columnIndex: number;
  switch (type) {
    case actions.CREATE_CARD_SUCCESS:
      const { newCard } = payload;
      columnIndex = newColumnState.findIndex((column: any) => column._id === newCard.column);
      newColumnState[columnIndex].cards.push(newCard);
      return newColumnState;

    case actions.UPDATE_CARD_SUCCESS:
      const { cardUpdated } = payload;
      columnIndex = newColumnState.findIndex((column: any) => column._id === cardUpdated.column);
      newColumnState[columnIndex].cards = newColumnState[columnIndex].cards.map((card: any) => {
        if (card._id === cardUpdated._id) {
          return cardUpdated;
        }
        return card;
      });
      return newColumnState;

    case actions.DELETE_CARD_REQUESTED:
      const { cardId, columnId } = payload;
      columnIndex = newColumnState.findIndex((column: any) => column._id === columnId);
      newColumnState[columnIndex].cards = newColumnState[columnIndex].cards.filter(
        (card: any) => card._id !== cardId
      );
      return newColumnState;
    default:
      return null;
  }
};

export default function column(
  state = initialState,
  { type, payload }: { type: string; payload: any }
): IKanbanColumState {
  let newColumnState;
  switch (type) {
    case actions.GET_COLUMN_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.GET_COLUMN_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        columns: payload.columns,
      };
    case actions.GET_COLUMN_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.SET_COLUMNS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.SET_COLUMNS_SUCCESS:
      let columnsSorted = payload.columns.sort((a: any, b: any) => a.boardIndex - b.boardIndex);

      let columnsWithCardsSorted = columnsSorted.map((column: any) => {
        column.cards.sort((a: any, b: any) => a.columnIndex - b.columnIndex);
        return column;
      });
      return {
        ...initialState,
        ...state,
        loading: false,
        columns: columnsWithCardsSorted,
      };
    case actions.UPDATE_COLUMN_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.UPDATE_COLUMN_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.UPDATE_COLUMN_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.UPDATE_COLUMNS_STATE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        columns: payload.newColumns,
      };
    case actions.UPDATE_COLUMNS_STATE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.CREATE_COLUMN_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        columnState: false,
      };
    case actions.CREATE_COLUMN_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        columns: incrementColumns(state.columns, payload.newColumn),
      };
    case actions.CREATE_COLUMN_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_COLUMN_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.DELETE_COLUMN_SUCCESS:
      newColumnState = state.columns.filter((column: any) => column._id !== payload.columnId);
      return {
        ...initialState,
        ...state,
        loading: false,
        columns: newColumnState,
      };
    case actions.DELETE_COLUMN_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CLEAR_COLUMN_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CLEAR_COLUMN_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        columns: [],
        columnState: false,
      };
    case actions.GET_CARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loadingCard: true,
        currentCard: undefined,
      };
    case actions.GET_CARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loadingCard: false,
        currentCard: payload.findCard,
      };
    case actions.GET_CARD_FAILED:
      return {
        ...initialState,
        ...state,
        loadingCard: false,
        error: payload.message,
      };
    case actions.CREATE_CARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CREATE_CARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        columns: cardActions(
          { columns: [...state.columns], newCard: payload.newCard },
          actions.CREATE_CARD_SUCCESS
        ),
      };
    case actions.CREATE_CARD_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.UPDATE_CARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.UPDATE_CARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        columns: cardActions(
          { columns: [...state.columns], cardUpdated: payload.cardUpdated },
          actions.UPDATE_CARD_SUCCESS
        ),
        currentCard: payload.cardUpdated,
      };
    case actions.UPDATE_CARD_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_CARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        columns: cardActions(
          { columns: [...state.columns], ...payload },
          actions.DELETE_CARD_REQUESTED
        ),
      };
    case actions.DELETE_CARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.DELETE_CARD_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CLEAR_CURRENT_CARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentCard: undefined,
      };
    case actions.CLEAR_CURRENT_CARD_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.REMOVE_CARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };

    case actions.REMOVE_CARD_SUCCESS:
      const columnId = state.columns.findIndex((column) => column._id === payload.columnId);
      const columnsCards = state.columns[columnId].cards.filter(
        (card) => card._id !== payload.cardId
      );
      const newColumns = state.columns;
      newColumns[columnId].cards = columnsCards;
      return {
        ...initialState,
        ...state,
        currentCard: undefined,
        loading: false,
        columns: newColumns,
      };
    default:
      return state;
  }
}
