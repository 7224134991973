import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class ProjectServices{
    
    static async getProject(data){
        return await new ApiClient(HOST_NAMES.KANBAN).get(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}`);
    }
    
    static async getProjects(data){
        return await new ApiClient(HOST_NAMES.KANBAN).get(`${API_ROUTES.KANBAN.MAIN_ROUTE}?title=${data.payload.title}&page=${data.payload.page}&limit=${data.payload.limit}`);
    }

    static async createProject(data){
        return await new ApiClient(HOST_NAMES.KANBAN).post(API_ROUTES.KANBAN.MAIN_ROUTE, {title: data.payload.title});
    }

    static async deleteProject(data){
        return await new ApiClient(HOST_NAMES.KANBAN).delete(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}`);
    }

    static async addUserToProject(data){
        return await new ApiClient(HOST_NAMES.KANBAN).put( `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/users`, {users: data.payload.users, role: data.payload.role});
    }

    static async deleteUserFromProject(data){
        return await new ApiClient(HOST_NAMES.KANBAN).patch( `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/users`, {users: data.payload.users, role: data.payload.role});
    }
    
    static async updateProject(data){
        return await new ApiClient(HOST_NAMES.KANBAN).put(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}`, data.payload.data);
    }

    static async createNotifications(data){
        return await new ApiClient(HOST_NAMES.KANBAN).post(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/notifications`, data.payload.data);
    }

    static async updateNotifications(data){
        return await new ApiClient(HOST_NAMES.KANBAN).put(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/notifications/${data.payload.notificationsId}`, data.payload.data);
    }

    static async setProjectRole(data){
        return await new ApiClient(HOST_NAMES.KANBAN).post(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/user/${data.payload.userId}/role`, {role: data.payload.role._id});
    }

    static async updateProjectRole(data){
        return await new ApiClient(HOST_NAMES.KANBAN).put(`${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/user/${data.payload.userId}/role/${data.payload.roleId}`, {role: data.payload.role._id});
    }
}