import ApiClient from "./ApiClient";
import { API_ROUTES, HOST_NAMES } from "../constants";

export default class ContactServices {
  static async getContacts(data) {
    return await new ApiClient(HOST_NAMES.USER).get(
      API_ROUTES.USER.MAIN_ROUTE,
      {
        params: {
          name: data.payload.name,
        },
      }
    );
  }
}
