import { call, put, takeEvery } from "redux-saga/effects";
import LabelServices from "../../../services/kanban/label";
import * as type from "../../types";

function* setLabels(payload) {
  yield put({ type: type.SET_LABELS_SUCCESS, payload: payload.payload });
}

function* createLabel(payload) {
  try {
    const { data } = yield call(LabelServices.createLabel, payload);
    yield put({ type: type.CREATE_LABEL_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.CREATE_LABEL_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* deleteLabel(payload) {
  try {
    const { data } = yield call(LabelServices.deleteLabel, payload);
    yield put({
      type: type.DELETE_LABEL_SUCCESS,
      payload: { data, labelId: payload.payload.labelId },
    });
  } catch (error) {
    yield put({
      type: type.DELETE_LABEL_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* labelSaga() {
  yield takeEvery(type.SET_LABELS_REQUESTED, setLabels);
  yield takeEvery(type.CREATE_LABEL_REQUESTED, createLabel);
  yield takeEvery(type.DELETE_LABEL_REQUESTED, deleteLabel);
}

export default labelSaga;
