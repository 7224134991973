import parse from "html-react-parser";

const user = () => JSON.parse(localStorage.getItem("user"));

function getString(data) {
  return (language = user()?.language ?? "en") => {
    return data[language];
  };
}

export const STRINGS = {
  LOADING_DATA: "loading data",

  PROCESSING_IMAGE: "Processing image",
  ACTIONS: {
    CANCEL: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Cancelar",
        en: "Cancel",
      }[language];
    },
    SAVE_CHANGES: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Salvar alterações",
        en: "Save changes",
      }[language];
    },
    ACCEPT: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Aceitar",
        en: "Accept",
      }[language];
    },
    CONTINUE: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Continuar",
        en: "Continue",
      }[language];
    },
    REMOVE: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Remover",
        en: "Remove",
      }[language];
    },
  },
  DIALOG_TITLES: {
    WARNING: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Atenção",
        en: "Warning",
      }[language];
    },
    ERROR: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Erro",
        en: "Error",
      }[language];
    },
    SUCCESS: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Sucesso",
        en: "Success",
      }[language];
    },
  },

  HOME: {
    CHOOSE_APPLICATION: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Escolha a aplicação que deseja acessar",
        en: "Choose the tool that you want to use",
      }[language];
    },
    ACCESS: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Acessar",
        en: "Access",
      }[language];
    },
  },
  CODES: {
    TITLE: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Códigos",
        en: "Codes",
      }[language];
    },
    NEW_PROJECT: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Novo projeto",
        en: "New project",
      }[language];
    },
    NO_PROJECTS_FOUND: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Nenhum projecto encontrado!",
        en: "No projects found!",
      }[language];
    },
    SEARCH: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Buscar",
        en: "Search",
      }[language];
    },
    SAVE_SECTION: {
      TITLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Salvar",
          en: "Save",
        }[language];
      },
      PROJECT_NAME: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Nome do projeto",
          en: "Project name",
        }[language];
      },
    },
    SHARE_SECTION: {
      TITLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Compartilhar",
          en: "Share",
        }[language];
      },
      SHARE_VIA_EMAIL: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Compartilhar via email",
          en: "Share via email",
        }[language];
      },
      CREATE_PUBLIC_LINK: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Criar link público",
          en: "Create public link",
        }[language];
      },
      COPY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Copiar",
          en: "Copy",
        }[language];
      },
      SHARE_WITH_TEAM: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Compartilhar com o time",
          en: "Share with the team",
        }[language];
      },
    },
  },
  KANBAN: {
    YOU_DO_NOT_BELONG_TO_PROJECT: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Você não pertence a este projeto",
        en: "You do not belong to this project",
      }[language];
    },
    PROJECTS: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Projetos",
        en: "Projects",
      }[language];
    },
    CREATE_PROJECT: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Criar projeto",
        en: "Create project",
      }[language];
    },
    PROJECTS_LIST: {
      NAME: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Nome",
          en: "Name",
        }[language];
      },
      LEADER: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Líder",
          en: "Leader",
        }[language];
      },
      ASSIGNED_USERS: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Usuários atribuidos",
          en: "Assigned users",
        }[language];
      },
      ACTIONS: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Ações",
          en: "Actions",
        }[language];
      },
      REMOVE_CARD: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Você tem certeza de que deseja remover este projeto? esta ação não pode ser desfeita!",
          en: "Are you sure that you want to remove this project? thi cation can not be undone!",
        }[language];
      },
    },
    BOARD: {
      ADD_MORE_PEOPLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Adicionar mais pessoas",
          en: "Add more people",
        }[language];
      },
      CARD: {
        DESCRIPTION: {
          TITLE: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Descrição",
              en: "Description",
            }[language];
          },
          PLACEHOLDER: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Adicione uma descrição...",
              en: "Add a description...",
            }[language];
          },
        },
        DATE: {
          TITLE: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Data",
              en: "Date",
            }[language];
          },
        },
        LABELS: {
          TITLE: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Rótulos",
              en: "Labels",
            }[language];
          },
          PLACEHOLDER: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Nome do rótulo",
              en: "Enter label text",
            }[language];
          },
          ADD_LABEL: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Adicionar rótulo",
              en: "Add label",
            }[language];
          },
        },
        ASSIGNED: {
          TITLE: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Atribuídos",
              en: "assigned",
            }[language];
          },
          ADD_USERS: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Adicionar usuários",
              en: "Add users",
            }[language];
          },
          REMOVE_USER: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Remover usuário",
              en: "Remove user",
            }[language];
          },
          SELECT_ONE_OR_MORE_USERS: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Selecione um ou mais usuários",
              en: "Select one or more users",
            }[language];
          },
        },
        TASKS: {
          TITLE: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Tarefas",
              en: "Tasks",
            }[language];
          },
          PLACEHOLDER: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Nome da tarefa",
              en: "Enter task",
            }[language];
          },
          ADD_TASK: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Adicionar uma tarefa",
              en: "Add a task",
            }[language];
          },
        },
        FEATURES: {
          COMMENTS: {
            TITLE: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Comentários",
                en: "Comments",
              }[language];
            },
            PLACEHOLDER: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Escreva um comentário...",
                en: "Add a comment...",
              }[language];
            },
            CANCEL: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Cancelar",
                en: "Cancel",
              }[language];
            },
            SEND: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Enviar",
                en: "Send",
              }[language];
            },
            EDITED: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "editado",
                en: "edited",
              }[language];
            },
          },
          HISTORY: {
            TITLE: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Histórico",
                en: "History",
              }[language];
            },
            NONE: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Nenhuma",
                en: "None",
              }[language];
            },
            NO_HISTORY_FOUND: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Nenhum histórico foi encontrado",
                en: "No history was found",
              }[language];
            },
            REFRESH: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Recarregar",
                en: "Refresh",
              }[language];
            },
            HISTORY_TITLES: {
              CARD_CREATED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "criou esse cartão",
                  en: "has created this card",
                }[language];
              },
              CARD_CLOSED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "fechou esse cartão",
                  en: "has closed this card",
                }[language];
              },
              TITLE_UPDATED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "atualizou o titulo desse cartão",
                  en: "has updated the card title",
                }[language];
              },
              DESCRIPTION_UPDATED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "alterou a descrição desse cartão",
                  en: "has changed the card description",
                }[language];
              },
              DATE_UPDATED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "atualizou a data desse cartão",
                  en: "has updated the card date",
                }[language];
              },
              TASK_CREATED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "criou uma nova tarefa",
                  en: "has created a new task",
                }[language];
              },
              TASK_REMOVED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "removeu uma tarefa",
                  en: "has removed a task",
                }[language];
              },
              TASK_COMPLETED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "completou a tarefa",
                  en: "has completed the task",
                }[language];
              },
              TASK_CANCELED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "cancelou a tarefa",
                  en: "has canceled the task",
                }[language];
              },
              USER_ASSIGNED: {
                FIRST_PART: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "atribuiu",
                    en: "has assigned",
                  }[language];
                },
                LAST_PART: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "a este cartão",
                    en: "to this card",
                  }[language];
                },
              },
              USER_UNASSIGNED: {
                FIRST_PART: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "removeu",
                    en: "has removed",
                  }[language];
                },
                LAST_PART: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "deste cartão",
                    en: "from this card",
                  }[language];
                },
              },
              COLUMN_CHANGED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "moveu esse cartão para outra coluna",
                  en: "has moved this card to another column",
                }[language];
              },
              COMMENT_ADDED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "adicionou um novo comentário",
                  en: "has added a new comment",
                }[language];
              },
              COMMENT_UPDATED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "alterou um comentário",
                  en: "has changed a comment",
                }[language];
              },
              COMMENT_REMOVED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "excluiu um comentário",
                  en: "has removed a comment",
                }[language];
              },
              LABEL_ADDED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "adicionou a tag",
                  en: "has added the label",
                }[language];
              },
              LABEL_REMOVED: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "removeu a tag",
                  en: "has removed the label",
                }[language];
              },
            },
          },
          COMMENT_ALERT_MODAL: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Você tem certeza que deseja excluir esse comentário?",
              en: "Are you sure you want to delete this comment?",
            }[language];
          },
        },
        CARD_URL_COPIED: (language = user()?.language ?? "en"): string => {
          return {
            "pt-Br": "URL do card copiada com sucesso!",
            en: "Card URL copied!",
          }[language];
        },
      },
      SETTINGS: {
        SIDE_BAR: {
          BACK_TO_PROJECT: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Voltar ao projeto",
              en: "Back to project",
            }[language];
          },
          PROJECT_SETTINGS: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Configurações do projeto",
              en: "Project Settings",
            }[language];
          },
        },
        SETTINGS_OPTIONS: {
          DETAILS: {
            DETAILS: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Detalhes",
                en: "Details",
              }[language];
            },
            NAME: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Nome",
                en: "Name",
              }[language];
            },
            PROJECT_TYPE: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Tipo de projeto",
                en: "Project type",
              }[language];
            },
            DESCRIPTION: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Descrição",
                en: "Description",
              }[language];
            },
            DESCRIPTION_PLACEHOLDER: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Adicione uma descrição para seu projeto...",
                en: "Add a description for your project...",
              }[language];
            },
            PROJECT_LEAD: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Líder do projeto",
                en: "Project lead",
              }[language];
            },
            EDIT_DETAILS: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Editar detalhes",
                en: "Edit details",
              }[language];
            },
            CANCEL: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Cancelar",
                en: "Cancel",
              }[language];
            },
            SAVE_CHANGES: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Salvar alterações",
                en: "Save changes",
              }[language];
            },
          },
          PEOPLE: {
            FILTERS: {
              PLACEHOLDER: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "Pesquisar por nomes ou endereços de e-mail...",
                  en: "Search for names or email addresses...",
                }[language];
              },
              ROLES: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "Cargos",
                  en: "Roles",
                }[language];
              },
            },
            ADD_PEOPLE: {
              SEARCH_INPUT: {
                PLACEHOLDER: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Pesquisar por pessoas",
                    en: "Search for People",
                  }[language];
                },
                PEOPLE: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Pessoas:",
                    en: "People:",
                  }[language];
                },
                SELECT_PEOPLE: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Selecionar",
                    en: "Select",
                  }[language];
                },
                LOADING_PEOPLE: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Carregando pessoas",
                    en: "Loading people",
                  }[language];
                },
              },
              PEOPLE_SELECTED: {
                PEOPLE_SELECTED: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Pessoa Selecionada:",
                    en: "People Selected:",
                  }[language];
                },
                NAME: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Nome",
                    en: "Name",
                  }[language];
                },
                ROLE: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Cargo",
                    en: "Role",
                  }[language];
                },
                NONE: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Nenhum",
                    en: "None",
                  }[language];
                },
              },
              ADD_PEOPLE: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "Adicionar Pessoa",
                  en: "Add People",
                }[language];
              },
              ROLE: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "Cargo:",
                  en: "Role:",
                }[language];
              },
              DROPDOWN_PLACEHOLDER: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "Selecione um cargo...",
                  en: "Select a role...",
                }[language];
              },
              CANCEL: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "Cancelar",
                  en: "Cancel",
                }[language];
              },
              ADD: (language = user()?.language ?? "en"): string => {
                return {
                  "pt-Br": "Adicionar",
                  en: "Add",
                }[language];
              },
            },
            PEOPLE_BODY: {
              PEOPLE_TOP: {
                NAME: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Nome",
                    en: "Name",
                  }[language];
                },
                EMAIL: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "E-mail",
                    en: "Email",
                  }[language];
                },
                ROLE: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Cargo",
                    en: "Role",
                  }[language];
                },
              },
              PEOPLE_SCROLL: {
                REMOVE_PEOPLE: {
                  REMOVE: (language = user()?.language ?? "en"): string => {
                    return {
                      "pt-Br": "Remover",
                      en: "Remove",
                    }[language];
                  },
                  MODAL_ALERT: (additionalText, language = user()?.language ?? "en"): string => {
                    return {
                      "pt-Br": `Você tem certeza que deseja remover ${additionalText} do projeto?`,
                      en: `Are you sure you want to remove ${additionalText} from the project?`,
                    }[language];
                  },
                },
                NONE: (language = user()?.language ?? "en"): string => {
                  return {
                    "pt-Br": "Nenhum",
                    en: "None",
                  }[language];
                },
              },
            },
            PEOPLE: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Pessoas",
                en: "People",
              }[language];
            },
          },
          NOTIFICATIONS: {
            NOTIFICATIONS: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Notificações",
                en: "Notifications",
              }[language];
            },
            EVENTS: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Eventos",
                en: "Events",
              }[language];
            },
          },
          ROLES: {
            ROLES: (language = user()?.language ?? "en"): string => {
              return {
                "pt-Br": "Cargos",
                en: "Roles",
              }[language];
            },
          },
        },
        ALERT_DIALOG_MODAL: {
          CANCEL: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Cancelar",
              en: "Cancel",
            }[language];
          },
          REMOVE: (language = user()?.language ?? "en"): string => {
            return {
              "pt-Br": "Remover",
              en: "Remove",
            }[language];
          },
        },
      },
    },
    COMPONENTS: {
      CUSTOM_INPUT: {
        ADD: (language = user()?.language ?? "en"): string => {
          return {
            "pt-Br": "Adicionar",
            en: "Add",
          }[language];
        },
      },
    },
  },
  PROFILE: {
    PAGE: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Perfil",
        en: "Profile",
      }[language];
    },
    ACCOUNT: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Conta",
        en: "Account",
      }[language];
    },
    SUBTITLE: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "As informações podem ser editadas",
        en: "The information can be edited",
      }[language];
    },
    FIRST_NAME: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Primeiro nome",
        en: "First Name",
      }[language];
    },
    LAST_NAME: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Último nome",
        en: "Last name",
      }[language];
    },
    EMAIL_ADDRESS: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Email",
        en: "Email address",
      }[language];
    },
    PHONE_NUMBER: (language = user()?.language ?? "en") => {
      return {
        "pt-Br": "Telefone",
        en: "Phone number",
      }[language];
    },
    COUNTRY: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "País",
        en: "Country",
      }[language];
    },
    LANGUAGE: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Idioma",
        en: "Language",
      }[language];
    },
    SAVE_DETAILS: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Salvar",
        en: "Save details",
      }[language];
    },
    PASSWORD_SECTION: {
      TITLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Senha",
          en: "Password",
        }[language];
      },
      SUBTITLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Atualizar senha",
          en: "Update password",
        }[language];
      },
      CURRENT_PASSWORD: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Senha atual",
          en: "Current password",
        }[language];
      },
      NEW_PASSWORD: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Nova senha",
          en: "New password",
        }[language];
      },
      CONFIRM_PASSWORD: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Confirme a nova senha",
          en: "Confirm password",
        }[language];
      },
      UPDATE_BUTTON: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "ATUALIZAR",
          en: "UPDATE",
        }[language];
      },
    },
  },
  MEET: {
    MONTHS: {
      JANUARY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Janeiro",
          en: "January",
        }[language];
      },

      FEBRUARY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Fevereiro",
          en: "February",
        }[language];
      },

      MARCH: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Março",
          en: "March",
        }[language];
      },

      APRIL: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Abril",
          en: "April",
        }[language];
      },

      MAY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Maio",
          en: "May",
        }[language];
      },

      JUNE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Junho",
          en: "June",
        }[language];
      },

      JULY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Julho",
          en: "July",
        }[language];
      },

      AUGUST: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Agosto",
          en: "August",
        }[language];
      },

      SEPTEMBER: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Setembro",
          en: "September",
        }[language];
      },

      OCTOBER: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Outubro",
          en: "October",
        }[language];
      },

      NOVEMBER: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Novembro",
          en: "November",
        }[language];
      },

      DECEMBER: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Dezembro",
          en: "December",
        }[language];
      },
    },
    DAYS: {
      SUNDAY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Domingo",
          en: "Sunday",
        }[language];
      },
      MONDAY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Segunda",
          en: "Monday",
        }[language];
      },

      TUESDAY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Terça",
          en: "Tuesday",
        }[language];
      },
      WEDNESDAY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Quarta",
          en: "Wednesday",
        }[language];
      },
      THURSDAY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "quinta",
          en: "Thursday",
        }[language];
      },
      FRIDAY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Sexta",
          en: "Friday",
        }[language];
      },
      SATURDAY: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Sábado",
          en: "Saturday",
        }[language];
      },
    },
    NEW_MEETING: {
      TITLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Nova reunião",
          en: "New meeting",
        }[language];
      },
      SUBTITLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Criar nova reunião",
          en: "Create a new meeting",
        }[language];
      },
    },
    JOIN_MEETING: {
      TITLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Entrar",
          en: "Join",
        }[language];
      },
      SUBTITLE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Com código",
          en: "via invitation link",
        }[language];
      },
      POPUP_LABEL: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Insira o ID da reunião",
          en: "Meeting ID",
        }[language];
      },
    },
    ROOM: {
      END_CALL: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Finalizar chamada",
          en: "End call",
        }[language];
      },
      WRITE_MESSAGE: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Escreva sua mensagem...",
          en: "Enter message...",
        }[language];
      },
      MEMBERS: (language = user()?.language ?? "en"): string => {
        return {
          "pt-Br": "Participantes",
          en: "Members",
        }[language];
      },
    },
  },
  SETUP: {
    SET_AVATAR: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Selecione uma imagem de perfil",
        en: "Set an avatar picture",
      }[language];
    },
    SELECT_AVATAR_TITLE: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Selecione uma imagem clicando na camera",
        en: "Click on the camera icon to select an image",
      }[language];
    },
    UPDATE_PASSWORD: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Insira uma nova senha",
        en: "Set a new password",
      }[language];
    },
    START_USING_APP: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Comece a utilizar a aplicação",
        en: "Start using the app",
      }[language];
    },
    BACK: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "VOLTAR",
        en: "BACK",
      }[language];
    },
    NEXT: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "AVANÇAR",
        en: "NEXT",
      }[language];
    },
    ACCESS_ENVIRONMENT_LABEL: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Acesse seu ambiente de trabalho clicando no botão abaixo",
        en: "Access your workspace clicking on the button below",
      }[language];
    },
    ACCESS_ENVIRONMENT_BUTTON: (language = user()?.language ?? "en"): string => {
      return {
        "pt-Br": "Acessar Plataforma",
        en: "Access workspace",
      }[language];
    },
  },
  CHAT: {
    WELCOME: {
      TITLE_H1: ({ firstName }) => {
        return parse(
          getString({
            "pt-Br": `Bem-vindo, <span>${firstName}!</span>`,
            en: `Welcome, <span>${firstName}!</span>`,
          })()
        );
      },
      TITLE_H3: getString({
        "pt-Br": "Escolha alguém para conversar.",
        en: "Please select a chat to Start messaging.",
      }),
    },
    SIDEBAR: {
      CHATS: getString({
        "pt-Br": "Conversas",
        en: "Chats",
      }),
      EMPTY_CHAT: getString({
        "pt-Br": "Busque por alguem para iniciar uma conversa 😄",
        en: "Shearch for someone to start a conversation 😄",
      }),
      NO_CHATS_FOUND: getString({
        "pt-Br": "Não consegui encontrar nenhum usuário 😅",
        en: "No users found 😅",
      }),
      NO_GROUPS_FOUND: getString({
        "pt-Br": "Não encontrei nenhum grupo com esse nome 😅",
        en: "I can't find any group with that name 😅",
      }),
      EMPTY_GROUP: getString({
        "pt-Br": "Parece que você não participa de nenhum grupo ainda 😅",
        en: "Looks like you not in a group yet 😅",
      }),
      GROUPS: getString({
        "pt-Br": "Grupos",
        en: "Groups",
      }),
      INPUT: {
        SEARCH_PLACE_HOLDER: getString({
          "pt-Br": "Buscar por...",
          en: "Search for...",
        }),
      },
      PROFILE: {
        ME: getString({
          "pt-Br": "Eu",
          en: "Me",
        }),
      },
    },
    GROUP: {
      ME: getString({
        "pt-Br": "Eu",
        en: "Me",
      }),
      CREATE_GROUP: {
        PLACEHOLDER: getString({
          "pt-Br": "Nome do grupo...",
          en: "Group's name...",
        }),
        ADD_PEOPLES: getString({
          "pt-Br": "Adicionar pessoas",
          en: "Add people",
        }),
        MADE_PUBLIC: getString({
          "pt-Br": "Tornar esse grupo publico?",
          en: "Make this group public?",
        }),
        YES: getString({
          "pt-Br": "Sim",
          en: "Yes",
        }),
        CANCEL: getString({
          "pt-Br": "Cancelar",
          en: "Cancel",
        }),
        CREATE: getString({
          "pt-Br": "Criar",
          en: "Create",
        }),
      },
    },
  },
};
