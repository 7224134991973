import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class ColumnServices{
    static async getColumns(data){
        return await new ApiClient(HOST_NAMES.KANBAN).get(
            `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns`);
    }

    static async createColumn(data){
        return await new ApiClient(HOST_NAMES.KANBAN).post(
            `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns`,{title: data.payload.title, index: data.payload.index});
    }

    static async deleteColumn(data){
        return await new ApiClient(HOST_NAMES.KANBAN).delete(
            `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}`);
    }

    static async updateColumn(data){
        return await new ApiClient(HOST_NAMES.KANBAN).put(
            `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}`, {index: data.payload.index, title: data.payload.title});
    }
}