import { HOST_NAMES } from "./hostNames";

interface RouteInterface {
  name: string;
  url: string;
}
const isDevelopment = process.env.NODE_ENV === "development";

export const API_HOSTS: RouteInterface[] = [
  {
    name: HOST_NAMES.CHAT,
    url: isDevelopment
      ? "http://localhost:5001"
      : "https://workspace-chat.herokuapp.com",
  },
  {
    name: HOST_NAMES.CODEPEN,
    url: isDevelopment
      ? "http://localhost:5002"
      : "https://workspace-codepen.herokuapp.com",
  },
  {
    name: HOST_NAMES.USER,
    url: isDevelopment
      ? "http://localhost:5003"
      : "https://workspace-user.herokuapp.com",
  },
  {
    name: HOST_NAMES.KANBAN,
    url: isDevelopment
      ? "http://localhost:5004"
      : "https://workspace-kanban.herokuapp.com",
  },
  {
    name: HOST_NAMES.MEET,
    url: isDevelopment
      ? "http://localhost:3001"
      : "https://workspace-meet.herokuapp.com",
  },
];
