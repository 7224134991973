import ApiClient from "@services/ApiClient";
import { API_ROUTES, HOST_NAMES } from "@/constants";
import { AxiosResponse } from "axios";
import { ICode } from "../domain/Code";
export interface GetCodesServiceResponse {
  data: {
    docs: ICode[];
    hasMore: boolean;
    hasNextPage: boolean;
    hasPrevPage: boolean;
    limit: number;
    nextPage: number;
    page: number;
    pagingCounter: number;
    totalDocs: number;
    totalPages: number;
  };
}
export class GetCodesService {
  static async handle({
    userId,
    name,
    page,
  }): Promise<AxiosResponse<GetCodesServiceResponse, any>> {
    return await new ApiClient(HOST_NAMES.CODEPEN).get(
      `${API_ROUTES.CODEPEN.MAIN_ROUTE}?userId=${userId}&name=${name}&page=${page}&limit=4`
    );
  }
}
