import ApiClient from "@services/ApiClient";
import { API_ROUTES, HOST_NAMES } from "@/constants";
import { AxiosResponse } from "axios";
import { ICode } from "../domain/Code";

export interface GetCodeByUrlServiceResponse {
  data: { code: ICode };
}

export class GetCodeByUrlService {
  static async handle(url: string): Promise<AxiosResponse<GetCodeByUrlServiceResponse, any>> {
    return await new ApiClient(HOST_NAMES.CODEPEN).get(`${API_ROUTES.CODEPEN.MAIN_ROUTE}/${url}`);
  }
}
