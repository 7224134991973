import { API_ROUTES, HOST_NAMES } from "@/constants";
import ApiClient from "../ApiClient";
import { HistoryPayloadData } from "@/features/kanban/domain";

export default class HistoryServices {
  static async getHistory(data: HistoryPayloadData) {
    return await new ApiClient(HOST_NAMES.KANBAN).get(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/history`
    );
  }
}
