import { IUser } from "@/features/user/domain/User";
import { BaseAction } from "@store/domain/Action";
import * as type from "@store/types";

interface RetrieveUserSessionPayload {
  user: IUser;
}
interface RetrieveUserSessionAction extends BaseAction {
  payload: RetrieveUserSessionPayload;
}

export function retrieveUserSession(
  payload: RetrieveUserSessionPayload
): RetrieveUserSessionAction {
  return {
    type: type.USER_RETRIEVE_SESSION_REQUESTED,
    payload,
  };
}
