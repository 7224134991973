import ApiClient from "../ApiClient";
import { API_ROUTES, HOST_NAMES } from "../../constants";

export default class TaskServices {
  static async createTask(data) {
    return await new ApiClient(HOST_NAMES.KANBAN).post(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/tasks`,
      { text: data.payload.text }
    );
  }

  static async updateTask(data) {
    return await new ApiClient(HOST_NAMES.KANBAN).put(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/tasks/${data.payload.taskId}`,
      { toggle: data.payload.toggle }
    );
  }

  static async deleteTask(data) {
    return await new ApiClient(HOST_NAMES.KANBAN).delete(
      `${API_ROUTES.KANBAN.MAIN_ROUTE}/${data.payload.projectId}/boards/${data.payload.boardId}/columns/${data.payload.columnId}/cards/${data.payload.cardId}/tasks/${data.payload.taskId}`
    );
  }
}
