import { actionChannel, call, put, takeEvery } from "redux-saga/effects";
import * as type from "@store/types";
import HistoryServices from "@/services/kanban/history";
import { HistoryPayloadData } from "@/features/kanban/domain";

function* getHistory(payload: HistoryPayloadData) {
  try {
    const { data } = yield call(HistoryServices.getHistory, payload);
    yield put({ type: type.GET_CARD_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: type.GET_CARD_HISTORY_FAILED,
      payload: { message: error?.response?.data ?? error.message },
    });
  }
}

function* clearHistory() {
  yield put({ type: type.CLEAR_HISTORY_SUCCESS, payload: {} });
}

function* historySaga() {
  yield takeEvery(
    yield actionChannel(type.GET_CARD_HISTORY_REQUESTED),
    getHistory
  );
  yield takeEvery(
    yield actionChannel(type.CLEAR_HISTORY_REQUESTED),
    clearHistory
  );
}

export default historySaga;
