import { ReactElement } from "react";
import { UserAvatarProps } from "./interfaces/UserAvatarProps";

import { Avatar } from "@mui/material";

function UserAvatar({
  avatar,
  width,
  height,
  avatar_url,
  maxHeight,
  alt,
  title,
  className,
  avatarAsFile,
}: UserAvatarProps): ReactElement {
  const backgroundImage: string = avatar_url
    ? `${avatar_url}`
    : avatar
    ? `data:image/jpeg;base64,${btoa(String.fromCharCode(...new Uint8Array(avatar.data)))}`
    : avatarAsFile
    ? URL.createObjectURL(avatarAsFile)
    : "";
  return (
    <>
      {backgroundImage !== "" ? (
        <Avatar
          className={className}
          title={title ?? ""}
          style={{
            width: width ?? "2.5rem",
            height: height ?? "2.5rem",
            ...(maxHeight && { maxHeight: maxHeight }),
            borderRadius: "50%",
          }}
          alt={alt ?? "user"}
          src={backgroundImage}
        />
      ) : (
        <Avatar
          className={className}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            background: "grey",
            borderRadius: "50%",
            ...(width && { width: width }),
            ...(height && { height: height }),
          }}
          src="https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png"
        />
      )}
    </>
  );
}

export default UserAvatar;
