import { IUser } from "@/features/user/domain/User";
import { IAvatar } from "../../../../domain/IAvatar";

interface IGroup {
  _id?: string;
  name: string;
  description?: string;
  avatar?: IAvatar;
  users: IUser[];
  admins: IUser[];
}

const subscribedGroups = "subscribedGroups";

export { IGroup, subscribedGroups };
