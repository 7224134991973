import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { MoveToInbox as InboxIcon, Menu as MenuIcon, Logout, Dashboard } from "@mui/icons-material";
import { userLogout } from "@store/actions/users";
import { RoutesPaths } from "@constants/constants";

import Cookies from "universal-cookie";

import { Link } from "react-router-dom";
import { UserInSession } from "@api/getSession";

function HamburgerMenu(): ReactElement {
  const cookies = new Cookies();

  const dispatch = useDispatch();

  const handleClick = async (): Promise<void> => {
    dispatch(
      userLogout({
        id: cookies.get("userId") ?? UserInSession._id,
      })
    );
    localStorage.removeItem("user");
    cookies.remove("userId");
    window.location.assign("/login");
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: string) => (
    <Box
      style={{ zIndex: "999999" }}
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {RoutesPaths.map((object) =>
          object.shoulBeShownOnMenu === true ? (
            <ListItem key={object.name} disablePadding>
              <Link style={{ textDecoration: "none", color: "black" }} to={object.path}>
                <ListItemButton>
                  <ListItemIcon>{object.icon ?? <InboxIcon />}</ListItemIcon>
                  <ListItemText primary={object.name} />
                </ListItemButton>
              </Link>
            </ListItem>
          ) : null
        )}
      </List>
      <Divider />
      <List>
        <ListItem key={"dashboard"} disablePadding>
          <Link
            onClick={(e) => {
              e.preventDefault();
              window.open("https://admin.js-workspace.com");
            }}
            to="/"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItemButton>
              <ListItemIcon>{<Dashboard />}</ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem key={"sair"} disablePadding>
          <Link onClick={handleClick} to="/" style={{ textDecoration: "none", color: "black" }}>
            <ListItemButton>
              <ListItemIcon>{<Logout />}</ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      <React.Fragment key={"left"}>
        <Button
          className="hamburgerMenu"
          style={{
            position: "absolute",
            color: "white",
          }}
          onClick={toggleDrawer("left", true)}
        >
          {" "}
          <MenuIcon />
        </Button>
        <Drawer anchor={"left"} open={state["left"]} onClose={toggleDrawer("left", false)}>
          {list("left")}
        </Drawer>
      </React.Fragment>
    </>
  );
}

export {HamburgerMenu};
