export const API_ROUTES = {
  CHAT: {
    LOGIN: "/api/auth/login",
    REGISTER: "/api/auth/register",
    LOGOUT: "/api/auth/logout",
    GET_CONTACTS: "/api/auth/allusers",
    SEND_MESSAGE: "/api/messages/addmsg",
    GET_MESSAGES: "/api/messages/getmsg",
    SET_AVATAR: "/api/auth/setAvatar",
    CONTACTS: "/api/contacts",
    CHANNELS: "/api/chat/channels",
    MESSAGES: "/api/messages",
    GROUPS: "/api/groups",
  },
  USER: {
    LOGIN: "/api/users/login",
    REGISTER: "/api/users/register",
    LOGOUT: "/api/users/logout",
    GET_CONTACTS: "/api/users",
    SET_AVATAR: "/api/users/setAvatar",
    MAIN_ROUTE: "/api/users",
    ROLES: "/api/roles",
    RECOVER_PASSWORD: "/api/users/recover",
    MEDIA: "/api/media",
  },
  CODEPEN: {
    MAIN_ROUTE: "/api/code",
    GENERATE_PUBLIC_URL: "/api/code/generatePublicUrl",
  },
  KANBAN: {
    MAIN_ROUTE: "/api/kanban/projects",
  },
};
