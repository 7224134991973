import { legacy_createStore as createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const store = compose(applyMiddleware(sagaMiddleware))(createStore)(rootReducer as any);

sagaMiddleware.run(rootSaga);

export default store;
