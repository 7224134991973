import {
  IKanbanProject,
  IKanbanProjectState,
} from "../../../features/kanban/domain";
import * as actions from "../../types";

const initialState: IKanbanProjectState = {
  projects: [],
  totalProjects: 0,
  currentProject: undefined,
  loading: false,
  loadingProject: false,
  createLoading: false,
  error: "",
};

const includeUserToProject = (
  currentProjectState: IKanbanProject,
  user: any
): IKanbanProject => {
  currentProjectState.includedUsers.push(user);
  return currentProjectState;
};

const deleteUserFromProject = (
  currentProjectState: IKanbanProject,
  user: any
): IKanbanProject => {
  let newIncludedUsers = currentProjectState.includedUsers.filter(
    (item) => item._id !== user._id
  );
  currentProjectState.includedUsers = newIncludedUsers;
  return currentProjectState;
};
const deleteProjectById = (
  projecstState: IKanbanProject[],
  projectId: string
): IKanbanProject[] =>
  projecstState.filter((project) => project._id !== projectId);

const updateCurrentProjectDetails = (
  currentProjectState: IKanbanProject,
  payload: any
): IKanbanProject => {
  const { title, description } = payload.data;
  if (title && title !== "") {
    currentProjectState.title = title;
  }

  if (description !== undefined) {
    currentProjectState.description = description;
  }

  return currentProjectState;
};

const handleUpdateUserProjectRole = (
  currentProjectState: IKanbanProject,
  payload: any,
  type: string
) => {
  let newState = currentProjectState;
  let newIncludedUsers;
  switch (type) {
    case "SET":
      newIncludedUsers = newState.includedUsers.map((user) => {
        if (user._id === payload.userId) {
          user.project = [payload.newProjectRole];
        }
        return user;
      });
      newState.includedUsers = newIncludedUsers;
      return newState;
    case "UPDATE":
      newIncludedUsers = newState.includedUsers.map((user) => {
        if (user._id === payload.userId) {
          let projectRoleIndex = user.project.findIndex(
            (project) => project.project === payload.projectId
          );
          if (projectRoleIndex !== null && projectRoleIndex !== undefined) {
            user.project[projectRoleIndex].role = {
              name: payload.role.name,
              _id: payload.role._id,
            };
          }
        }
        return user;
      });
      newState.includedUsers = newIncludedUsers;
      return newState;
    default:
      return null;
  }
};

const handleSetNotification = (
  currentProjectState: IKanbanProject,
  notificationId: string
) => {
  currentProjectState.notifications = notificationId;
  return currentProjectState;
};

export default function project(state = initialState, { type, payload }) {
  switch (type) {
    case actions.GET_PROJECT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loadingProject: true,
        projects: [],
        currentProject: undefined,
        error: "",
      };
    case actions.GET_PROJECT_SUCCESS:
      return {
        ...initialState,
        ...state,
        loadingProject: false,
        currentProject: payload.project,
        error: "",
      };
    case actions.GET_PROJECT_FAILED:
      return {
        ...initialState,
        ...state,
        loadingProject: false,
        error: payload.message.message,
      };
    case actions.GET_PROJECTS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        projects: [],
        totalProjects: 0,
        currentProject: undefined,
        error: "",
      };
    case actions.GET_PROJECTS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        projects: payload.projects,
        totalProjects: payload.total,
        currentProject: undefined,
        error: "",
      };
    case actions.GET_PROJECTS_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };

    case actions.SET_PROJECT_BOARD_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        projects: [],
        error: "",
      };
    case actions.SET_PROJECT_BOARD_SUCCESS:
      let newProject = state.currentProject;
      newProject.board = payload.board;
      return {
        ...initialState,
        ...state,
        loading: false,
        currentProject: newProject,
        error: "",
      };
    case actions.CREATE_PROJECT_REQUESTED:
      return {
        ...initialState,
        ...state,
        createLoading: true,
        error: "",
      };
    case actions.CREATE_PROJECT_SUCCESS:
      return {
        ...initialState,
        ...state,
        createLoading: false,
        currentProject: payload.newProject,
        error: "",
      };
    case actions.CREATE_PROJECT_FAILED:
      return {
        ...initialState,
        ...state,
        createLoading: false,
        error: payload.message.message,
      };
    case actions.DELETE_PROJECT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        error: "",
      };
    case actions.DELETE_PROJECT_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        projects: deleteProjectById(state.projects, payload.payload.projectId),
        totalProjects: state.totalProjects - 1,
        currentProject: null,
        error: "",
      };
    case actions.DELETE_PROJECT_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message.message,
      };
    case actions.ADD_USER_TO_PROJECT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        error: "",
      };
    case actions.ADD_USER_TO_PROJECT_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentProject: includeUserToProject(
          state.currentProject,
          payload.newUser
        ),
        error: "",
      };
    case actions.ADD_USER_TO_PROJECT_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.DELETE_USER_FROM_PROJECT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentProject: deleteUserFromProject(
          state.currentProject,
          payload.users[0]
        ),
        error: "",
      };
    case actions.DELETE_USER_FROM_PROJECT_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: "",
      };
    case actions.DELETE_USER_FROM_PROJECT_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.CLEAR_CURRENT_PROJECT_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        error: "",
      };
    case actions.CLEAR_CURRENT_PROJECT_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentProject: undefined,
        error: "",
      };
    case actions.UPDATE_PROJECT_REQUESTED:
      return {
        ...initialState,
        ...state,
        currentProject: updateCurrentProjectDetails(
          state.currentProject,
          payload
        ),
      };
    case actions.UPDATE_PROJECT_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.UPDATE_PROJECT_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.SET_PROJECT_ROLE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.SET_PROJECT_ROLE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentProject: handleUpdateUserProjectRole(
          state.currentProject,
          payload,
          "SET"
        ),
      };
    case actions.SET_PROJECT_ROLE_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.UPDATE_PROJECT_ROLE_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        currentProject: handleUpdateUserProjectRole(
          state.currentProject,
          payload,
          "UPDATE"
        ),
      };
    case actions.UPDATE_PROJECT_ROLE_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
      };
    case actions.UPDATE_PROJECT_ROLE_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    case actions.SET_PROJECT_NOTIFICATIONS_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: false,
        currentProject: handleSetNotification(
          state.currentProject,
          payload.projectId
        ),
      };
    case actions.SET_PROJECT_NOTIFICATIONS_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
      };
    default:
      return state;
  }
}
