import { IHistoryState } from "@/features/kanban/domain";
import * as actions from "@store/types";
import { StateStructure } from "../domain/StateStructure";

const initialState: IHistoryState = {
  history: [],
  loading: false,
  currentCardId: "",
  error: "",
};

export default function history(
  state = initialState,
  { type, payload }: StateStructure
): IHistoryState {
  switch (type) {
    case actions.GET_CARD_HISTORY_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
        history: [],
        currentCardId: "",
      };
    case actions.GET_CARD_HISTORY_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        history: payload.history,
        currentCardId: payload.targetCard,
      };
    case actions.GET_CARD_HISTORY_FAILED:
      return {
        ...initialState,
        ...state,
        loading: false,
        error: payload.message,
        currentCardId: "",
      };
    case actions.CLEAR_HISTORY_REQUESTED:
      return {
        ...initialState,
        ...state,
        loading: true,
      };
    case actions.CLEAR_HISTORY_SUCCESS:
      return {
        ...initialState,
        ...state,
        loading: false,
        history: [],
        currentCardId: "",
      };
    default:
      return state;
  }
}
