import ApiClient from "@services/ApiClient";
import { API_ROUTES, HOST_NAMES } from "@/constants";
import { AxiosResponse } from "axios";
import { ICode } from "../domain/Code";

export class CreateCodeService {
  static async handle(data: ICode): Promise<AxiosResponse<any, any>> {
    return await new ApiClient(HOST_NAMES.CODEPEN).post(API_ROUTES.CODEPEN.MAIN_ROUTE, data);
  }
}
