import { ServiceStructure } from "@/app/structures/ServiceStructure";
import { API_ROUTES, HOST_NAMES } from "@/constants";
import ApiClient from "@/services/ApiClient";
import { AxiosResponse } from "axios";

export interface CreateChatGroupServiceParams {
  name: string;
  avatarUrl?: string;
  description?: string;
  members: string[];
  privacy?: "private" | "public";
}

export interface CreateChatGroupServiceResponse {
  data: {
    newGroup: any;
  };
}

export class CreateChatGroupService
  implements
    ServiceStructure<
      CreateChatGroupServiceParams,
      CreateChatGroupServiceResponse
    >
{
  async handle(
    params: CreateChatGroupServiceParams
  ): Promise<AxiosResponse<CreateChatGroupServiceResponse, any>> {
    return await new ApiClient(HOST_NAMES.CHAT).post(API_ROUTES.CHAT.GROUPS, {
      ...params,
    });
  }
}
